<template>
<div>

{{ this.$store.state.roleMasterObj[this.params.value].name }}

</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    methods: {
        btnClickedHandler() {
            this.params.context.componentParent.selectedUser = this.params.value
            //alert(this.params.context.componentParent.selectedUser);

        },
        transformRole(){
            //if ( this.params.value == 1 ){ return "Pesant"; }
            //if ( this.params.value == 100 ){ return "Super Admin"; }
            return this.$store.state.roleMasterObj[this.params.value].name

        }
    }
});
</script>