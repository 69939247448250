<template>

<v-container class="py-5" style="height: 100%;">

    <div>
      <v-row>
        <v-col md="8">
          <span class="text-button">Multi-Factor Authentication</span>
          <v-divider></v-divider>  
        </v-col>              
        <v-col md="8">
          <span>Your administrator requires you to enroll in multi-factor authentication before using this application. Input your cell phone number and press enroll to complete your verification. You must have logged in recently to enroll, for security purposes.</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="4">
          <v-text-field v-model="phoneNum" label="Phone Number" placeholder="999 123 4567" class="pa-0"></v-text-field>
        </v-col>
        <v-col md="4" class="pt-4">
          <v-btn small color="blue darken-1" @click="enroll()" :loading="saving" :disabled="saving"><span class="white--text">Enroll</span></v-btn>
          <v-btn small color="blue darken-1" @click="signOut()" class="ml-2"><span class="white--text">Sign Out</span></v-btn>
        </v-col>              
      </v-row>

    </div>

    <v-dialog v-model="twofamodal" max-width="500">
        <v-toolbar color="primary" dark flat height=60>
            <v-toolbar-title class="text-subtitle-1">SMS Code</v-toolbar-title>
        </v-toolbar>
      <v-form ref="twofaForm" lazy-validation @submit.prevent="submit2fa()">
        <v-card>
            <v-card-text>
            An SMS vertification code was sent to your mobile phone. Please enter the code below to continue.
            <v-text-field solo v-model="verificationCode" label="Code" class="pt-4"></v-text-field>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cleanUp(); twofamodal = false">Close</v-btn>
                <v-btn color="blue darken-1" text type="submit">Submit</v-btn>
            </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <div id="recaptcha-container"></div>

</v-container>
</template>

<script>

import { getAuth, signOut, sendPasswordResetEmail , PhoneMultiFactorGenerator, PhoneAuthProvider, RecaptchaVerifier, multiFactor } from "firebase/auth";
import db from "@/main.js";
import { doc, setDoc, addDoc } from "firebase/firestore"; 

    export default {
        name: 'Users',
        data() {
            return {
                twofamodal: false,
                verificationCode: null,
                verificationId: null,
                phoneNum: null,
                recaptchaVerifier: null,
                reComp: false,
                saving: false,
            }
        },
        components: {

            
        },
        methods: {

            begin(){

            },
            signOut() {
              console.log("Signing Out: ", this.project)
              //this.$store.state.unsubscribeDocProject[this.project]()
              const auth = getAuth();
              signOut(auth).then(() => {
                // Sign-out successful.
                
                console.log("Signed out successfully.");
              }).catch((error) => {
                // An error happened.
                console.log(error);

              });
            },
            enroll(){
              this.saving = true
              if (this.phoneNum == null){ return }

              if (this.phoneNum.match(/\d/g).length !== 10){ 
                this.$store.commit('alertpop', { message: 'Please input a properly formatted phone number.', type: "warning" });
                this.saving = false
                return 
                }

              console.log("enroll", this.phoneNum.replace(/\D/g,''))

              const auth = getAuth()
              const user = auth.currentUser
              const mfaUser = multiFactor(user); 
              var phoneNumber = "+1" + this.phoneNum.replace(/\D/g,'')

              this.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container',  {
                  'size': 'invisible',
                  'callback': function(response) {
                  // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
                  //onSolvedRecaptcha();
                  }
                  }, auth);
              console.log(user)
              
              mfaUser.getSession().then((multiFactorSession) =>  {
                // Specify the phone number and pass the MFA session.
                var phoneInfoOptions = {
                  phoneNumber: phoneNumber,
                  session: multiFactorSession
                };
                var phoneAuthProvider = new PhoneAuthProvider(auth);
                // Send SMS verification code.
                return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier)
                    .then((verificationId) => {
                        this.verificationId = verificationId
                        this.twofamodal = true
                    })
                    .catch((error) =>  {
                      if (error.code == "auth/requires-recent-login"){ 
                        this.$store.commit('alertpop', { message: 'Enrolling requires a recent login. Please log out and back in to try again.', type: "warning" });
                        this.cleanUp()
                        }
                    })
              })



            },
            submit2fa() {
                const auth = getAuth()
                const user = auth.currentUser
                const mfaUser = multiFactor(user);
                var phoneNumber = "+1" + this.phoneNum.replace(/\D/g,'')

                console.log("then veriID")
                var cred = PhoneAuthProvider.credential(this.verificationId, this.verificationCode);
                var multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
                // Complete sign-in.
                //return this.resolver.resolveSignIn(multiFactorAssertion)
                return mfaUser.enroll(multiFactorAssertion, phoneNumber)                
                .then((userCredential) =>  {
                    // User successfully signed in with the second factor phone number.
                    console.log("Enrolled: ", userCredential);
                    this.setEnrolled()
                    this.twofamodal = false
                    this.cleanUp()
                    this.$router.push('/projects');
                    })
                .catch((error) =>  {
                    console.log(error)
                    if (error.code == "auth/invalid-verification-code"){
                        this.$store.commit('alertpop', { message: 'Invalid verification code, please try again.', type: "warning" });             
                    }
                })
            },            
            cleanUp(){
                this.verificationCode = null
                this.verificationId = null
                this.phoneNum = null
                this.recaptchaVerifier.clear()
                this.saving = false
            },
            async setEnrolled(){
              this.$store.state.user.enrolledmfa = true
              await setDoc(doc(db, "users", this.$store.state.user.id), { enrolledmfa: true }, { merge: true });
              this.reComp = !this.reComp          
            },

            async setUnenrolled(){
              this.$store.state.user.enrolledmfa = false
              await setDoc(doc(db, "users", this.$store.state.user.id), { enrolledmfa: false }, { merge: true });              
              this.reComp = !this.reComp
            },                      
          

        },
        computed: {          
          testcomp(){
            return false
          }
        },      

        beforeMount() {
          console.log("MFA page")
        },

        mounted() {
          //this.$router.push('/projects');
          //console.log(this.$route.path)
        },
        watch: { 



        }


    }
</script>

<style>



</style>