<template>

<v-container v-if="this.$store.getters.loaded == false">
    <v-overlay opacity="1" value="true">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
</v-container>

<v-container v-else class="py-5 pa-0 main-view">

<div class="whole-table">

    <div id="ev-list-bar" class="pa-1 pr-2">
      <v-icon icon="mdi-border-inside" color="info" class="pt-4 pl-4"></v-icon><span class="text-button pl-4 pt-1" style="color: white; font-size: large;">Project Logs</span>
      <v-spacer></v-spacer>      
      <v-btn variant="text" color="white" @click="loadLogs()">
          <v-icon>mdi-refresh</v-icon>
      </v-btn>
   
    </div>        
      <ag-grid-vue style="width: 100%; height: calc(100% - 40px);"
        class="ag-theme-material"
        :autoGroupColumnDef="autoGroupColumnDef"
        :defaultColDef="defaultColDef"
        :columnDefs="columnDefs"
        :groupDisplayType="groupDisplayType"
        :groupRowRendererParams="groupRowRendererParams"           
        :rowData="rowData"
        :rowGroupPanelShow="rowGroupPanelShow"
        :sideBar="sideBar"
        :groupIncludeFooter="true"
        :aggFuncs="aggFuncs"
        headerHeight="40"
        rowSelection="multiple"
        @grid-ready="onGridReady"
        @column-row-group-changed="onColumnRowGroupChanged"
        @row-selected="onRowSelected"
        @firstDataRendered="onFirstDataRendered">
    </ag-grid-vue>
</div>

</v-container>
</template>

<script>
/*
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { AgGridVue } from "@ag-grid-community/vue";
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';
*/
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue3';


import { doc, setDoc, writeBatch, query, collection, getDocs, orderBy } from "firebase/firestore";
import db from "@/main.js";

    export default {
        name: 'Home',
        data() {
            return {
                columnDefs: null,
                autoGroupColumnDef: null,
                context: null,
                defaultColDef: null,
                rowData: null,
                rowSelection: null,
                rowGroupPanelShow: null,
                aggFuncs: null,
                gridApi: null,
                columnApi: null,
                sideBar: false,
                groupDisplayType: null,
                groupRowRenderer: null,                
                //modules: [ClientSideRowModelModule, AllModules, RowGroupingModule, SetFilterModule, MenuModule, ColumnsToolPanelModule, FiltersToolPanelModule],
                currentUID: "Initial",
                currentProject: null,

            }
        },
        components: {
            AgGridVue,
        },
        methods: {
          async loadLogs(){
            try {
            const r = query(collection(db,  "projects/" + this.currentProject + "/logs"), orderBy("timedate", "desc"));
            const querySnapshot = await getDocs(r);
            let tempRay = []
            let docData = null
            this.rowData = []

            querySnapshot.forEach((doc) => {
                docData = {}
                docData = doc.data()
                docData.time = doc.data().timedate.toDate().toLocaleTimeString('en-US')
                docData.date = doc.data().timedate.toDate().toLocaleDateString()
                tempRay.push(docData);
            });
            this.rowData = [...tempRay]
            } catch(e) {
                console.error(e);
              }          

          },

            onGridReady(params) {
                this.gridApi = params.api;
                this.columnApi = params.columnApi;                         
            },
            onRowSelected(event) {

            },
            onFirstDataRendered(event) {
                           
               
            },
            onColumnRowGroupChanged(event){
              //resize group column when grouped
              this.columnApi.autoSizeColumn('ag-Grid-AutoColumn');
            },
            getSelectedRows() {
                const selectedNodes = this.gridApi.getSelectedNodes();
                const selectedData = selectedNodes.map( node => node.data );
                console.log(selectedData);
                const selectedDataStringPresentation = selectedData.map( node => `${node.make} ${node.model}`).join(', ');
                alert(`Selected nodes: ${selectedDataStringPresentation}`);
            },
                     

        },

        beforeMount() {
          //A refresh happened and first data is not loaded yet.
          if (this.$store.getters.loaded == false){ this.$store.commit('beginLoading') }

          
          
          //get the current project id from the URL
          this.currentProject = this.$route.query.project
          this.$store.state.currentProject = this.$route.query.project

          //if project is already in store then load data to rows, if not then load database and wait for watcher
          if (this.currentProject in this.$store.state.loadedProjects && this.$store.state.loadedProjects[this.currentProject] == true ) {
            //Load data into table if project data was already loaded
            this.loadLogs()
            //Load title if project data was already loaded
            if (this.$store.state.initializing.projects == true){ document.title = "Vantage - Documentation - " + this.$store.state.projMasterObj[this.currentProject].name }
            } else {
            this.$store.dispatch('loadProject', { projid: this.currentProject })
            }      

          this.columnDefs = [
                { headerName: 'Date', field: 'date', sortable: true, filter: true, resizable: true, enableRowGroup: true, width: 100,},  
                { headerName: 'Time', field: 'time', sortable: true, filter: true, resizable: true, width: 125},  
                { headerName: 'User', field: 'username', sortable: true, filter: true, resizable: true, width: 150, enableRowGroup: true, },  
                { headerName: 'E-Mail', field: 'email', sortable: true, filter: true, resizable: true, enableRowGroup: true, },  
                { headerName: 'UID', field: 'uid', sortable: true, filter: 'agTextColumnFilter', resizable: true, enableRowGroup: true, },
                { headerName: 'Action', field: 'action', sortable: true, filter: 'agTextColumnFilter', resizable: true, enableRowGroup: true, flex: 1},
            ];
          this.autoGroupColumnDef = { headerName:'Group',}
          this.defaultColDef = { resizable: true, menuTabs: ['filterMenuTab'], icons: { menu: '<i class="v-icon notranslate mdi mdi-filter-menu" style="font-size: 16px;"/>' },};
          this.rowGroupPanelShow = 'always';
          this.context = {componentParent: this};
          this.groupDisplayType = 'groupRows'
          this.groupRowRendererParams = { innerRenderer: 'ListEvGroup' }          

        },

        mounted() {        
        
          
        },
        unmounted() {


        },          
        computed: {
          projName(){
            return this.$store.state.projMasterObj[this.$route.query.project].name
          },
        },
        watch: { //watch for database changes and update the list


          "$store.state.initializing.projects"(nv) {
            console.log("project change", nv)
            if (nv == true){
              document.title = "Vantage - " + this.$store.state.projMasterObj[this.currentProject].name
             }

          },
          "$store.state.loadedProjects": {
            handler: function (val, oldVal) {
              //if new project is loaded check if its set to true and then load the row data
              if (this.currentProject in val && val[this.currentProject] == true){ 
                this.loadLogs()

                if (this.$store.state.initializing.projects == true){ document.title = "Vantage - Documentation - " + this.$store.state.projMasterObj[this.currentProject].name }

                //console.log("row data: " + this.$store.state.docuMasterRay[this.currentProject])
                }
      
            },
            deep: true
          },

        }

    }
</script>

<style scoped>

.no-active {
  color: rgba(255, 255, 255, 0.7) !important
}


.ag-theme-alpine div.ag-row {
    font-size: 13px !important;
}

.ag-header-cell-label {
    font-family: "Roboto", sans-serif !important;
    text-transform: uppercase !important;
    font-size: 0.75rem !important;
    font-weight: 500;
    letter-spacing: 0.0892857143em !important;
}

#ev-list-bar {
    display: flex;
    flex-wrap: nowrap;
    background-color: #3d4856;
    white-space: nowrap;
    overflow: hidden;

}

.whole-table {
    justify-content: center;
    width: 95%; 
    height: 95%;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.main-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
} 
</style>
