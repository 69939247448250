<template>
<div>

<v-btn variant="text" icon="mdi-cog" color="red lighten-2" @click="btnClickedHandler()">

</v-btn>

</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    methods: {
        btnClickedHandler() {
            this.params.context.componentParent.selectedOrg = this.params.value
            this.params.context.componentParent.settings = true;
            //alert(this.params.context.componentParent.selectedUser);

        }    
    }
});
</script>