<template>
<div align="center">

    <v-chip size="small" variant="flat" dark :class="scoretextColor()" :color="scoreColor()">{{ this.params.value }}</v-chip>

</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    methods: {
        scoreColor(){
            if ( this.params.value == 0 ){ return "red"; }
            if ( this.params.value == 25 ){ return "#ffc000"; }
            if ( this.params.value == 50 ){ return "#ffff00"; }
            if ( this.params.value == 75 ){ return "#92d050"; }
            if ( this.params.value == 100 ){ return "#00b050"; }           

        },
        scoretextColor(){
            if ( this.params.value == 0 ){ return "white--text"; }
            if ( this.params.value == 25 ){ return "white--text"; }
            if ( this.params.value == 50 ){ return "black--text"; }
            if ( this.params.value == 75 ){ return "white--text"; }
            if ( this.params.value == 100 ){ return "white--text"; }           

        }
    }
});
</script>