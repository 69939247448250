<template>
<div style="display: flex; justify-content: center;" class="pt-4">
    <v-icon color="success" v-if="this.params.value == true">mdi-arrow-up-bold-box</v-icon>
    <v-icon color="red" v-if="this.params.value == false">mdi-arrow-down-bold-box</v-icon>
</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    methods: {

    }
});
</script>