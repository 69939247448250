<template>
<div tabindex="0" style="display: flex;" @blur="closeList($event)">
    <v-spacer v-if="this.params.value == null && openlist == false"  @click="openlist = true" style="height: 30px"></v-spacer>

    <span v-if="selectedUser != null && openlist == false"  @click="openlist = true">{{ this.params.value }}</span>

    <div tabindex="1" v-if="openlist == true" style="display: flex; flex-wrap: wrap; align-items: center;">
        <v-select id="vsel" :items="$store.state.FDUserRay" item-title="name" v-model="selectedUser" return-object density="compact" >
			<template v-slot:append>
				<v-btn id="okbtn" variant="text" icon="mdi-check-bold" size="small" color="green lighten-2" @click="okButton()"></v-btn>           
                <v-btn id="xbtn" variant="text" icon="mdi-close" size="small" color="red lighten-2" @click="xButton()"></v-btn> 
			</template>         
        </v-select>
    </div>

</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    data() {
        return {
            openlist: false,
            items: ["1", "2"],
            selectedUser: {},
        }
    },    
    methods: {
        test(){
            console.log(this.selectedUser)
        },
        closeList(evt){

            if ('relatedTarget' in evt && evt.relatedTarget != null && 'id' in evt.relatedTarget && evt.relatedTarget.id == "vsel"){ return }
            if ('relatedTarget' in evt && evt.relatedTarget != null && 'id' in evt.relatedTarget && evt.relatedTarget.id == "okbtn"){ return }
            if ('relatedTarget' in evt && evt.relatedTarget != null && 'id' in evt.relatedTarget && evt.relatedTarget.id == "xbtn"){ return }
            this.openlist = false

        },
        okButton(){
            if (Object.keys(this.selectedUser).length !== 0 && this.selectedUser.name.length !== 0) { this.params.context.componentParent.singleFDUserAssignment(this.params.data.id, this.selectedUser) }
        },
        xButton() {
            this.openlist = false
            //console.log("open set", this.openlist)
        },
        settingsBtnClicked() {
            this.params.context.componentParent.selectedOrg = this.params.value
            this.params.context.componentParent.settings = true;
            //alert(this.params.context.componentParent.selectedUser);

        },
        openBtnClicked() {
            //console.log(this.params.value)
            this.params.context.componentParent.openProject(this.params.value, "pop")

            //alert(this.params.context.componentParent.selectedUser);

        }    
    },
    mounted() {    
        //console.log("paramas: ", this.params)
        if(this.params.node.group == false && this.params.data.assigned != null){this.selectedUser = this.params.data.assigned}
    }

});
</script>