<template>
      <div class="ag-overlay-loading-center" style="background-color: red; color: white;">
       {{ params.loadingMessage }}
      </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    methods: {

    }
});
</script>