<template>
<div>

{{ currentTopic }}

</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    methods: {
        statusText(){
            if ( this.params.value == 1 ){ return "Client Complete"}
            if ( this.params.value == 2 ){ return "Assessor Complete"}
            return "Not Complete"
        },
    },

  computed: {

    currentTopic(){
    //if(this.params.node != null && this.params.node.group == true){ console.log('1', this.params); return }
    //if(this.params.value != null && 'topic' in this.params.data){return this.$store.state.topicList[this.params.data.topic[0]].name}
    //  if('topic' in this.params.data){return this.$store.state.topicList[this.params.data.topic[0]].name}
    if (this.params.node.field == "topic"){ return this.$store.state.topicList[this.params.value].name}
    if (this.params.node.field == "status"){ return this.statusText()}

      return this.params.value
      
    },
  }    
});
</script>