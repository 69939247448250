<template>
<div class="logincontainer">

        <div class="loginleft">

                <v-card elevation="0" width="450" class="pr-5">
                    
                <v-form @submit.prevent="submit()" id="loginform">                    
                    <v-card-title>Sign In</v-card-title>
                    <v-card-subtitle>Continue to Vantage assessment portal</v-card-subtitle>
                    <v-card-text>
                        Email Address*
                        <v-text-field solo v-model="email" label="Email"></v-text-field>
                        Password*
                        <v-text-field solo v-model="password" label="Password" type="password"></v-text-field>
                    </v-card-text>

                    <div>
                        <v-row>
                            <v-col md="12" style="display: flex; justify-content: center;">
                                <v-btn rounded type="submit" color="success" form="loginform" width="200" :loading="saving" :disabled="saving">Sign In</v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col md="12" style="display: flex; justify-content: center;">
                                <span class="text-overline">Or login with</span>
                            </v-col>
                        </v-row>       
                        <v-row>
                            <v-col md="12" style="display: flex; justify-content: center;">
                                <v-btn color="primary" width="125" class="mr-2" @click='googleSignIn()'><v-icon small>mdi-google</v-icon>&nbsp;<span class="text-body-2 text-capitalize">Google</span></v-btn>
                                <v-btn color="primary" width="125" class="ml-2" @click='microsoftSignIn()'><v-icon small>mdi-microsoft</v-icon>&nbsp;<span class="text-body-2 text-capitalize">Microsoft</span></v-btn>
                            </v-col>
                        </v-row>                                      
                    </div>
                </v-form>
                </v-card>


        </div><div class="loginright">
            
                <v-card elevation="0"  width="600" outlined color="transparent">
                    
                    <v-card-title class="text-h4 font-weight-bold">Welcome to <v-img contain :src="require('@/assets/logo4.svg')" height="100" width="360"></v-img></v-card-title>
                    <v-card-text>Please login to continue..</v-card-text>

                </v-card>
        </div>

    <v-dialog v-model="twofamodal" max-width="500">
        <v-toolbar color="primary" dark flat height=60>
            <v-toolbar-title class="text-subtitle-1">SMS Code</v-toolbar-title>
        </v-toolbar>
      <v-form ref="twofaForm" lazy-validation @submit.prevent="submit2fa()">
        <v-card>
            <v-card-text>
            An SMS vertification code was sent to your mobile phone. Please enter the code below to continue.
            <v-text-field solo v-model="verificationCode" label="Code" class="pt-4"></v-text-field>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cleanUp(); twofamodal = false">Close</v-btn>
                <v-btn color="blue darken-1" text type="submit">Submit</v-btn>
            </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="passwordmodal" max-width="500">
        <v-toolbar color="primary" dark flat height=60>
            <v-toolbar-title class="text-subtitle-1">Vantage Password</v-toolbar-title>
        </v-toolbar>
      <v-form ref="twofaForm" lazy-validation @submit.prevent="promptForPassword()">
        <v-card>
            <v-card-text>
            Please confirm your built-in Vantage password to link to your external account.
            <v-text-field solo v-model="appPassword" label="Password" type="password" class="pt-4"></v-text-field>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cleanUp(); passwordmodal = false">Close</v-btn>
                <v-btn color="blue darken-1" text type="submit">Submit</v-btn>
            </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>    
  
<div id="recaptcha-container"></div>

</div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, OAuthProvider, getMultiFactorResolver, PhoneMultiFactorGenerator, PhoneAuthProvider, RecaptchaVerifier, linkWithCredential } from "firebase/auth";

export default {
    name: 'Login',
    data() {
        return {
            email: '',
            password: '',
            verificationId: null,
            verificationCode: null,
            twofamodal: false,
            resolver: null,
            recaptchaVerifier: null,
            saving: false,
            pendingCred: null,
            errorEmail: null,
            mfError: null,
            appPassword: null,
            passwordmodal: false,
        }
    },

    methods: {
    submit() {        
        this.saving = true
        const auth = getAuth();
        //auth.tenantId = 'Primary-Web-d10fo'
        signInWithEmailAndPassword(auth, this.email, this.password)
            .then((userCredential) => {
                // Signed in without MFA
                const user = userCredential.user;
                console.log(userCredential);
                this.$router.push('/auth');
            })
            .catch((error) =>  {
                if (error.code == 'auth/multi-factor-auth-required') {
                    this.mfError = error
                    this.resolver = error.resolver;
                    this.start2fa()

                } else if (error.code == 'auth/wrong-password') {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(error.code, error.message);
                    this.$store.commit('alertpop', { message: 'Invalid email address or password, please try again.', type: "warning" });
                    this.cleanUp()
                }
            });
    },
    start2fa(){
        const auth = getAuth();
        this.resolver = getMultiFactorResolver(auth, this.mfError);
        console.log("multifactor", this.resolver)
        // Ask user which second factor to use.
        if (this.resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
            var phoneInfoOptions = {
            multiFactorHint: this.resolver.hints[0],
            session: this.resolver.session
            };
            console.log("phone auth")
            var phoneAuthProvider = new PhoneAuthProvider(auth);
            console.log("recap")
            this.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container',  {
                'size': 'invisible',
                'callback': function(response) {
                // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
                //onSolvedRecaptcha();
                }
                }, auth);

            console.log("recap complete", phoneInfoOptions)
            // Send SMS verification code
            return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier)
                .then((verificationId) => {
                    this.verificationId = verificationId
                    this.twofamodal = true
                })  

        } else {
            console.log("Unsupported second factor")
            this.cleanUp()
        }  

    }, 
    submit2fa() {
        console.log("then veriID")
        var cred = PhoneAuthProvider.credential(this.verificationId, this.verificationCode);
        var multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
        // Complete sign-in.
        return this.resolver.resolveSignIn(multiFactorAssertion)
        .then((userCredential) =>  {
            // User successfully signed in with the second factor phone number.
            console.log(userCredential);
            this.twofamodal = false
            this.$router.push('/auth');
            })
         .catch((error) =>  {
             console.log(error)
            if (error.code == "auth/invalid-verification-code"){
                this.$store.commit('alertpop', { message: 'Invalid verification code, please try again.', type: "warning" });                
            }
         })
    },
    cleanUp(){
        this.resolver = null
        this.verificationCode = null
        this.verificationId = null
        if(this.recaptchaVerifier){this.recaptchaVerifier.clear()}
        this.saving = false
    },
    googleSignIn(){        
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');        
        signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            console.log("Logged in with Google: ", user, token)
            this.$router.push('/auth');
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            //this.errorEmail = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            console.log("Error from Google: ", errorCode, errorMessage)

            if (error.code == 'auth/multi-factor-auth-required') {
                this.mfError = error
                this.resolver = error.resolver;
                this.start2fa()
            }             

            if (error.code === 'auth/internal-error') {
                this.$store.commit('alertpop', { message: 'You are not currently authorized to use this application.', type: "warning" }); 
                this.saving = false  
                return
            }

            if (error.code === 'auth/account-exists-with-different-credential') {
                this.pendingCred = error.credential;
                console.log("Account exists")
                this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" }); 
                this.saving = false  
                return
            }
        });        
    },
    microsoftSignIn(){        
        const auth = getAuth();
        const provider = new OAuthProvider('microsoft.com');
        provider.addScope('profile');
        provider.addScope('email');        
        signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = OAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            console.log("Logged in with Microsoft: ", user, token)
            this.$router.push('/auth');
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            this.errorEmail = error.customData.email;
            // The AuthCredential type that was used.
            this.pendingCred = OAuthProvider.credentialFromError(error);            
            console.log("Error from Microsoft: ", errorCode, errorMessage)

            if (error.code == 'auth/multi-factor-auth-required') {                
                this.mfError = error
                this.resolver = error.resolver;
                this.start2fa()                
               return
            }             

            if (error.code === 'auth/internal-error') {
                this.$store.commit('alertpop', { message: 'You are not currently authorized to use this application.', type: "warning" }); 
                this.saving = false  
                return
            }

            if (error.code === 'auth/account-exists-with-different-credential') {
                console.log("Account exists")
                //this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" }); 
                this.saving = false  
                this.passwordmodal = true
                return
            }
        });        
    },    
   
    promptForPassword(){
        const auth = getAuth();
        signInWithEmailAndPassword(auth, this.errorEmail, this.appPassword).then((result) => {

            return linkWithCredential(result.user, this.pendingCred);

            }).then(() => {
                //Account successfully linked to the existing Firebase user.
                console.log("link completed")
                this.$router.push('/auth');

            }).catch((error) => {
                console.log("link error: ", error)
                
                if (error.code == 'auth/wrong-password') {
                    this.$store.commit('alertpop', { message: 'You have entered an incorrect password.', type: "warning" });                     
                }
                if (error.code == 'auth/multi-factor-auth-required') {
                    this.$store.commit('alertpop', { message: 'Please disable multi-factor authentication to link your Microsoft Account.', type: "warning" }); 
                    this.passwordmodal = false
                    /*
                    this.mfError = error
                    this.resolver = error.resolver;
                    this.passwordmodal = false
                    this.start2fa()
                    */
                }                  
                
            });
    } 

},
    beforeMount() {
        document.title = "Vantage - Login";
        //console.log(this.$store.state.count);
    },
    mounted() {
        //router.go()
    }
}
</script>

<style scoped>

.logincontainer {
    display: flex; 
    height: calc(100vh);
}

.loginleft {
    flex: 0 0 50%;
    background-color: #FFFFFF;
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.loginright {
    flex: 1;
    background-image: url("~@/assets/bg_image.png");
    display: flex;
    justify-content: center;
    align-items: center;
    
}



</style>