<template>

<div v-if="this.$store.getters.loaded == false">
    <v-overlay opacity="1" value="true">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
</div>

<div v-else class="py-5 pl-5" style="width: 100%; height: 100%;">
<FileMonitor />


  <v-row justify="center" style="width: 100%; height: 90%;">
    <v-col md="12">
    <ag-grid-vue style="width: 100%; height: 100%;"
        class="ag-theme-material"
        :autoGroupColumnDef="autoGroupColumnDef"
        :defaultColDef="defaultColDef"
        :columnDefs="columnDefs"
        :rowData="rowData"
        :rowGroupPanelShow="rowGroupPanelShow"
        :sideBar="sideBar"
        :groupIncludeFooter="true"
        :aggFuncs="aggFuncs"
        rowSelection="single"
        @grid-ready="onGridReady"
        @column-row-group-changed="onColumnRowGroupChanged"
        @row-selected="onRowSelected"
        @firstDataRendered="onFirstDataRendered">
    </ag-grid-vue>
    </v-col>
  </v-row>

  <v-row justify="center">
    <v-col md="11">

      <v-btn small dark @click="sideBar = !sideBar">Sidebar</v-btn>
      <v-btn small dark @click="drawerright = !drawerright">RightNav</v-btn>



    </v-col>
  </v-row>

<RightNav :showhide="drawerright" />


</div>


</template>

<script>
import RightNav from '@/components/RightNav.vue'
import FileMonitor from '@/components/FileMonitor.vue'
import db from "@/main.js";
/*
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { AgGridVue } from "@ag-grid-community/vue";
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-material.css';
*/
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue3';

import { collection, doc, getDoc, getDocs, onSnapshot, query } from "firebase/firestore";
import ListScore from '@/components/ListScore.vue'

    export default {
        name: 'Home',
        data() {
            return {
                columnDefs: null,
                autoGroupColumnDef: null,
                defaultColDef: null,
                rowData: {uid: "Loading..."},
                rowSelection: null,
                rowGroupPanelShow: null,
                aggFuncs: null,
                gridApi: null,
                columnApi: null,
                sideBar: false,
                //modules: [ClientSideRowModelModule, AllModules, RowGroupingModule, SetFilterModule, MenuModule, ColumnsToolPanelModule, FiltersToolPanelModule],
                tab: null,
                drawerright: false,
                drawerbottom: false,
                currentUID: "Initial",
                currentProject: null,
                unsubscribeDocProject: null,
                test1: {},
                breadcrumbs: [
                  {
                    text: 'Vantage',
                    disabled: false,
                    href: '/',
                  },
                  {
                    text: 'Projects',
                    disabled: false,
                    href: '/',
                  },
                  {
                    text: 'Project X',
                    disabled: true,
                    href: '/',
                  },
                ],
                savings: false,
            }
        },
        components: {
            AgGridVue,
            RightNav,
            FileMonitor
        },
        methods: {
            onGridReady(params) {
                this.gridApi = params.api;
                this.columnApi = params.columnApi;                         
            },
            onRowSelected(event) {
              if (event.node.selected){
              //Set currently selected UID in the store  
                if ( event.node.data ) { //blank rows arent selected
                  this.$store.state.currentUID = event.node.data.uid;
                  this.currentUID = event.node.data.uid;
                  if ( this.drawerright == false ) { this.drawerright = true }
                }
              }
            },
            onFirstDataRendered(event) {
              //Wait 1 second and then select the first row
              setTimeout(this.gridApi.getRowNode('0').setSelected(true),1000)   
               
            },
            onColumnRowGroupChanged(event){
              //resize group column when grouped
              this.columnApi.autoSizeColumn('ag-Grid-AutoColumn');
            },
            getSelectedRows() {
                const selectedNodes = this.gridApi.getSelectedNodes();
                const selectedData = selectedNodes.map( node => node.data );
                console.log(selectedData);
                const selectedDataStringPresentation = selectedData.map( node => `${node.make} ${node.model}`).join(', ');
                alert(`Selected nodes: ${selectedDataStringPresentation}`);
            },
            getfbData2() {

                this.gridApi.getRowNode('0').setSelected(true);        

            },
            changeRMenuWidth() {
              this.$store.commit('changedocuRMenuWidth');
            },
            getData(){
              console.log("getting data")
              //Get data and updated
              if (this.$store.state.unsubscribeDocProject != null){console.log("Unsubbed Doc Project"); this.$store.state.unsubscribeDocProject();}
              const q = query(collection(db, "projects/" + this.currentProject + "/requirements"));
              this.$store.state.docuMaster[this.currentProject] = {}
              this.$store.state.docuMasterRay[this.currentProject] = []
              this.$store.state.unsubscribeDocProject = onSnapshot(q, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                  
                  if (change.type === "added") {
                    //Vue.set(this.$store.state.docuMaster, change.doc.id, change.doc.data()); 
                    this.$store.state.docuMaster[this.currentProject][change.doc.id] = change.doc.data()
                    this.$store.state.docuMasterRay[this.currentProject].push(change.doc.data());   
                  }
                  if (change.type === "modified") {
                    //Vue.set(this.$store.state.docuMaster, change.doc.id, change.doc.data());
                    this.$store.state.docuMaster[this.currentProject][change.doc.id] = change.doc.data()
                    //change.doc.data().project = this.currentProject
                    let docChange = change.doc.data()
                    docChange["project"] = this.currentProject
                    this.$store.state.docuMasterChange = docChange
                    //store.state.docuMasterChange = change.doc.data();
                    console.log("dm change: ", this.$store.state.docuMasterChange)
                  }
                  if (change.type === "removed") {
                      //console.log("Removed city: ", change.doc.data());
                  }
                });
                //console.log(store.state.docuMaster);
              });
            },
            filePop(){
              this.$store.state.filePopup = !this.$store.state.filePopup
            },
            roundScore(score){
              if (score >= 0 && score <= 10) { return 0 } //Very low
              if (score >= 11 && score <= 32) { return 25 } //Low
              if (score >= 33 && score <= 65) { return 50 } //Moderate
              if (score >= 66 && score <= 89) { return 75 } //High
              if (score >= 90 && score <= 100) { return 100 } //Very high
            },

        },

        beforeMount() {
          
          //this.$store.dispatch('startDocProject', "payload")
          this.currentProject = this.$route.query.project
          this.getData()

          console.log("Query: ", this.$route.query.project)

            
          this.columnDefs = [
                { headerName: 'UID', field: 'uid', sortable: true, filter: true, resizable: true },
                { headerName: 'Domain', field: 'dom', sortable: true, filter: true, resizable: true, enableRowGroup: true },
                { headerName: 'Family', field: 'family', sortable: true, filter: true, resizable: true, enableRowGroup: true },
                { headerName: 'Requirement', field: 'req', sortable: true, filter: true, resizable: true, flex: 1  },
                { headerName: 'Policy', field: 'polscore', sortable: true, filter: true, resizable: true, cellRendererFramework: ListScore, aggFunc: 'myAvg', width: 110},
                { headerName: 'Process', field: 'procscore', sortable: true, filter: true, resizable: true, cellRendererFramework: ListScore,  aggFunc: 'myAvg', width: 110 }
            ];
          this.autoGroupColumnDef = { headerName:'Group',}
          this.defaultColDef = { resizable: true, };
          this.aggFuncs = {
              // this overrides the grids built-in sum function
              'myAvg': params => {
                  let sum = 0
                  let cnt = 0
                  params.values.forEach(value => {sum = sum + parseInt(value, 10); cnt++});
                  //console.log(sum, cnt)
                  return  Math.trunc(sum / cnt)
                  //return this.roundScore(sum / cnt);
              }
          };
        },
        created(){
          
        },
        mounted() {
          document.title = "Vantage - Project"
          console.log(this.$store.state.projMasterObj[this.currentProject].name)
          document.title = "Vantage - Project - " + this.$store.state.projMasterObj[this.currentProject].name;
          //document.title = "Vantage - " + this.$store.state.projectList[this.currentProject].name
          this.rowData = this.$store.state.docuMasterRay[this.currentProject];
          this.rowGroupPanelShow = 'always';
          
          console.log("mounted home")
          
        },
        computed: {

        },
        watch: { //watch for database changes and update the list
          "$store.state.docuMasterChange"(nv) {
            console.log("watcher change", nv)
            if (nv.project == this.currentProject){
              this.gridApi.forEachNode((rowNode, index) => {
                  if (rowNode.data.uid == nv.uid){
                    rowNode.setData(nv)
                  }
              });
            }
          }
        }
    }
</script>

<style scoped>

.no-active {
  color: rgba(255, 255, 255, 0.7) !important
}


.ag-theme-material div.ag-row {
    font-size: 13px !important;
}

</style>
