<template>
<div id="app">
  <v-app class="defVapp">
    <Navbar />
    <v-main class="mx-4">
      <router-view/>
    </v-main>

      <v-snackbar v-model="alert" timeout="5000" :color="this.$store.state.alerttype" shaped>
         <v-icon v-if="this.$store.state.alerttype == 'success'">mdi-check-circle</v-icon>
         <v-icon v-if="this.$store.state.alerttype == 'warning'">mdi-alert</v-icon>
         {{ this.$store.state.alertmessage }}   
      </v-snackbar>

  </v-app>
</div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'


export default {
  name: 'App',
  components: { Navbar },
  data: () => ({
    //
  }),
  computed: {
    alert: {
      get () {
        return this.$store.state.alert
      },
      set (value) {
        this.$store.commit('alert', value)
        }
    },
  },
};
</script>

<style>
.defVapp {

  background-color: #6e6e6e2a !important;
}
</style>