<template>

    <v-bottom-sheet v-model="showhide" width="500" hide-overlay persistent no-click-animation>
      <v-sheet color="#00000087" class="text-center" height="172px">

        <v-card color="#00000087" dark>
            <v-card-title class="text-button text-white text-left">File Queue &nbsp; <v-chip color="white" text-color="black" size="x-small">{{ this.$store.state.FileQueue.length }} </v-chip></v-card-title>
            <v-card-subtitle>
                <v-chip size="small" variant="flat" color="green">{{ this.$store.state.uploadFile.status }}</v-chip>
            </v-card-subtitle>

            <v-card-text class="text-white">
            {{ this.$store.state.uploadFile.name }}
            <v-progress-linear height="5" striped :model-value="this.$store.state.uploadFile.progress" color="green"></v-progress-linear>
            <v-btn v-if="this.$store.state.uploadFile.status != 'Paused'" variant="text" height="40px" right width="40px" @click="this.stopFile"><v-icon>mdi-stop</v-icon></v-btn>
            <v-btn v-if="this.$store.state.uploadFile.status != 'Paused'" variant="text" height="40px" right width="40px" @click="this.pauseFile"><v-icon>mdi-pause</v-icon></v-btn>
            <v-btn v-if="this.$store.state.uploadFile.status == 'Paused'" variant="text" height="40px" right width="40px" @click="this.resumeFile"><v-icon>mdi-play</v-icon></v-btn>
            </v-card-text>

 
        </v-card>

      </v-sheet>
    </v-bottom-sheet>

</template>

<script>
export default {
  name: 'FileMonitor',
  props: {
      test: Boolean,
      },
  data() {
    return {
        prop: null,
        sheet: true,
    }
  },
  methods: {
    genColor (i) {
    return this.colors[i]
    },
    pauseFile(){
      this.$store.state.uploadFile.task.pause()
    },
    resumeFile(){
      this.$store.state.uploadFile.task.resume()
    },
    stopFile(){
      this.$store.state.uploadFile.task.cancel()
    },

    },
computed: {
  showhide: {
    get () {
        if ( this.$store.state.FileQueue.length > 0 ){ return true }
        if ( this.$store.state.FileQueue.length == 0 ){ return false }
        return false
      //return this.$store.state.filePopup
    },
    set (value) {
      this.$store.commit('filePopup', value)
    }
  }
}

};
</script>