<template>
    <div>        

        <v-navigation-drawer ref="drawer" location="right" :width="navigation.width" id="rightDrawer" color="#b5b5b5" class="pl-1" v-model="localShowHide" >
          <div class="resize-border" ref="border"></div>
              <v-tabs class="fixed-tabs-bar" slider-color="green" v-model="tab" fixed-tabs bg-color="#3d4856" theme="dark" height="40">
                  
                <v-tab v-show="projectType != 'i1'" value="policy">
                Policies
                </v-tab>
                <v-tab v-show="projectType != 'i1'" value="process">
                Process
                </v-tab>
                <v-tab value="implemented">
                Implemented
                </v-tab>                
                <v-tab value="chat">
                Chat
                </v-tab>
            </v-tabs>

                  <div v-show="tab != 'chat'" style="background-color: #233a62; display: flex; flex-wrap: nowrap; align-items: center; height: 64px;" class="pt-2;">

                  <tooltip position="bottom-right" text="Show illustrative procedures">
                      <v-icon :color="illpolcolor" @click="implShowCards.showpip = !implShowCards.showpip" class="ml-5">
                      mdi-book-open-outline
                      </v-icon>
                  </tooltip>
                  &nbsp;&nbsp;
                  <tooltip position="bottom-right" text="Show scoring">
                      <v-icon :color="scorecolor" @click="implShowCards.showscoring = !implShowCards.showscoring">
                      mdi-dots-square
                      </v-icon>
                  </tooltip>
                  &nbsp;&nbsp;
                  <tooltip position="bottom-right" text="Show gaps">
                      <v-icon :color="gapscolor" @click="implShowCards.showgaps = !implShowCards.showgaps">
                      mdi-alert
                      </v-icon>
                  </tooltip>
                  &nbsp;&nbsp;
                  <tooltip position="bottom-right" text="Show test results">
                      <v-icon  :color="tresultscolor" @click="implShowCards.showtresults = !implShowCards.showtresults">
                      mdi-view-list
                      </v-icon>
                  </tooltip>
                  &nbsp;&nbsp;
                  <tooltip position="bottom-right" text="Show options">
                      <v-icon :color="optionscolor" @click="implShowCards.showoptions = !implShowCards.showoptions">                       
                      mdi-tune
                      </v-icon>
                  </tooltip>
                  <v-spacer></v-spacer>
                    <v-chip variant="elevated" elevation="4" color="#3d4856" label class="ma-2" ><span class="text-caption"> {{ currentTopic }} </span></v-chip>
                    <v-chip variant="elevated" elevation="4" color="#d3d3d3" label class="ma-2" @click="test()"><span class="text-caption"> {{ currentObj.uid }} </span></v-chip>
                  </div>   

            <div v-if="this.sideLoading" class="pl-4 pr-4">
              <v-overlay opacity="0.75" model-value class="align-center justify-center" contained>
                <v-progress-circular color="primary" indeterminate :size="120">
                  Loading...
                </v-progress-circular>
              </v-overlay>
            </div>

            <v-window v-else v-model="tab" style="background-color: transparent">
              <!-- POLICY TAB -->
                <v-window-item style="overflow: auto; height: calc(100vh - 215px);" class="mt-2" v-if="projectType != 'i1'" value="policy">

            
                        <v-container class="py-5">



                        <v-row>
                            <v-col md="12">
                                <v-card color="#0969ab87" theme="dark">
                                    <v-card-title class="text-button"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Requirement</v-card-title>
                                        <v-card-subtitle>
                                        {{ currentObj.req }}
                                        </v-card-subtitle>
                                    <v-card-actions>

                                    </v-card-actions>
                                </v-card>
                            </v-col>

                        </v-row><v-row>

                            <v-col md="12" v-if="showpip">
                                <v-card color="#3d9e3dcf" theme="dark">
                                        <v-card-subtitle>
                                        {{ currentObj.illpol }}
                                        </v-card-subtitle>
                                </v-card>
                            </v-col>                        
                            
                        </v-row><v-row>

                            <v-col md="12" v-if="showscoring">
                                <v-card color="#434f57cf" theme="dark">
                                  <v-card-title class="text-button"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Policy Scoring</v-card-title>
                                  <v-card-subtitle>
                                    Similarity: {{ similarity }} % <br>
                                    Calculated score:  {{ currentPolicyScore }} %
                                  </v-card-subtitle>
                                        <v-card-text>
                                          <v-list flat>
                                              <v-list-item v-for="(item,index) in currentObj.elements" :key="'item-' + index">

                                                  <v-list-item-action>
                                                    <v-checkbox v-model="item.satisfied" color="green lighten-1"></v-checkbox>
                                                  </v-list-item-action>

          
                                                  <span class="text-caption">{{ item.text }}</span>


                                              </v-list-item>
                                          </v-list>

                                          <v-slider v-model="currentObj.tierscore" :tick-labels="ticksLabels" :max="2" step="1" ticks="always" tick-size="8" color="orange"></v-slider>

                                          <v-row>
                                            <v-col md="4" align="left">
                                              No policy
                                            </v-col>

                                            <v-col md="4" align="center">
                                              Undocumented policy
                                            </v-col>

                                            <v-col md="4" align="right">
                                              Documented policy
                                            </v-col>
                                          </v-row>                                                                                   
                                      </v-card-text>

                                    <v-card-actions>                                      
                                        <v-spacer></v-spacer>
                                    </v-card-actions>

                                </v-card>
                            </v-col>

                          </v-row><v-row>

                            <v-col md="12">
                                <v-card color="#0969abcf" theme="dark">
                                    <v-card-title class="text-button"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Policy Language<v-spacer></v-spacer><v-btn icon @click="refreshPolicy()"><v-icon>mdi-arrow-u-left-top</v-icon></v-btn></v-card-title>
                                    <v-card-subtitle>Populate the language from your policies which meets the requirement.</v-card-subtitle>
                                    <v-card-text>
                                        <v-textarea v-model="currentObj.poltxt" solo light></v-textarea>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-row><v-row>

                            <v-col md="12">
                                <v-card color="#ffffffcf" @drop="drop($event, {drop: 'polass'})" @dragover.prevent>
                                  <v-card-title class="text-button"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Associated Policies</v-card-title>
                                  <v-card-subtitle>
                                    Select the associated policy files and page number
                                  </v-card-subtitle>
                                    <v-card-text>
                                      <v-data-table
                                        :headers="headers"
                                        :items="associatedPolicyCompute"
                                        :items-per-page="-1"
                                        :loading="tableLoading"
                                        loading-text="Loading... Please wait"                                             
                                        hide-default-footer
                                        class="elevation-1"
                                      >
                                        <template v-slot:item.name="{ item }">
                                          <a :href="item.url" target="_blank">{{ item.name }}</a>
                                        </template>

                                        <template v-slot:item.actions="{ item }">
                                          <v-icon small @click="remPolicyAssociation(item)"> mdi-delete </v-icon>
                                        </template>

                                        <template v-slot:item.page="{ item }">
                                          <input style="outline: 1px solid #0000002b" size="4" v-model="associatedPolicyComputeObj[item.id].policypage[currentUID]" outlined dense hide-details="true">
                                        </template>


                                      </v-data-table>                                                                                 
                                    </v-card-text>

                                    <v-card-actions>                                      
                                        <v-spacer></v-spacer>

                                    </v-card-actions>

                                </v-card>
                            </v-col>

                        </v-row>
                       <v-row>

                            <v-col md="12">
                                <v-card color="#ffffffcf" id="filescard"  @drop="drop($event)" @dragenter="dragging = 'elevation-1 green lighten-4'" @dragend="dragging='elevation-1'" @dragleave="dragging='elevation-1'" @dragover.prevent>
                                  <v-card-title class="text-button"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Document Repository</v-card-title>
                                  <v-card-subtitle>
                                    Upload or delete policy and process files which will be used to associate with a requirement. You can drag and drop multiple files on this panel to upload.
                                  </v-card-subtitle>
                                    <v-card-text>
                                      <v-data-table                                        
                                        show-select
                                        fixed-header
                                        :height="allDocsCompute.length > 8 ? 32 * 10 : undefined"
                                        :headers="polfileListHeaders"
                                        :items="allDocsCompute"
                                        :items-per-page="5"
                                        :search="fileSearch"
                                        :loading="tableLoading"
                                        loading-text="Loading... Please wait"                                             
                                        v-model="filesSelected"                                        
                                        class="elevation-1"
                                      >
                                        <template v-slot:top>
                                          <div style="display: flex; flex-wrap: wrap;">
                                          <v-text-field v-model="fileSearch" label="Search" dense clearable hide-details class="pa-6"></v-text-field>
                                          <v-btn @click="addMultiPolicyAssociation()" small color="green" class="ma-2 mt-4 white--text"><v-icon left>mdi-plus</v-icon>Policy</v-btn>
                                          <v-btn @click="deleteMultiFiles()" small color="red" class="ma-2 mt-4 white--text">Delete<v-icon right>mdi-delete</v-icon></v-btn>
                                          </div>
                                        </template>                                      

                                        <template v-slot:item.name="{ item }">
                                          <a :href="item.url" target="_blank">{{ item.name }}</a>&nbsp;
                                          <v-tooltip bottom  v-if="item.datediff > 90">
                                              <template v-slot:activator="{ props }">                                              
                                                 <v-icon theme="dark" small v-bind="props" color="red">mdi-alert-circle</v-icon>
                                              </template>
                                              <span>Evidence out of date</span>
                                          </v-tooltip>                                           
                                        </template>

                                        <template v-slot:item.actions="{ item }">
                                          <v-icon small @click="deleteFile(item)"> mdi-delete </v-icon>
                                        </template>

                                        <template v-slot:item.addpolicy="{ item }">
                                          <v-icon small @click="addPolicyAssociation(item)"> mdi-pencil-plus </v-icon>
                                        </template>
                                        
                                      </v-data-table>                                                                                 
                                    </v-card-text>

                                    <v-card-actions>
                                      <v-row align="center" class="pb-3">
                                        <v-col md="12">
                                          <div style="display: flex; flex-wrap: wrap;">
                                          <v-file-input label="File input" dense v-model="$store.state.chosenFile" hide-details="true" class="text-caption">
                                            <template v-slot:label>
                                              <span class="text-caption">File Input</span>
                                            </template>                                            
                                          </v-file-input>
                                          &nbsp;&nbsp;
                                          <v-btn small @click="upFile($store.state.chosenFile)">Upload</v-btn>
                                          </div>                                                                  
                                        </v-col>

                                      </v-row> 
                                    </v-card-actions>

                                </v-card>
                            </v-col>

                        </v-row>                       
                        <v-row>

                            <v-col md="12" v-if="showgaps">
                                <v-card color="#c02727cf" theme="dark">
                                    <v-card-title class="text-button"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Policy Gaps</v-card-title>
                                    <v-card-subtitle>Gaps that have been identified related to your submitted policies.</v-card-subtitle>
                                    <v-card-text>
                                        <v-textarea v-model="currentObj.polgaps" solo light></v-textarea>
                                    </v-card-text>
                                    <v-card-actions right>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                        </v-row><v-row>

                            <v-col md="12" v-if="showtresults">
                                <v-card color="#0969ab87" theme="dark">
                                    <v-card-title class="text-button"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Test Results</v-card-title>
                                    <v-card-subtitle>{{ testResults }}</v-card-subtitle>
                                </v-card>
                            </v-col>

                        </v-row><v-row>

                            <v-col md="12" v-if="showoptions">
                                <v-card color="#3d9e3dcf" theme="dark">
                                    <v-card-title class="text-button"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Options</v-card-title>
                                    <v-card-subtitle>Options for completion of this requirement</v-card-subtitle>
                                    <v-card-text>
                                      <v-row class="pa-0 ma-0 pt-2 pl-3" align="center">
                                          <v-col md="3" class="pa-0">
                                            <v-checkbox hide-details="true" v-model="currentObj.clientcomplete" class="ma-0 pa-0"><template v-slot:label><span id="checkboxLabel" class="text-caption">Client Complete</span></template></v-checkbox>
                                          </v-col>
                                          <v-col md="3" class="pa-0">
                                            <v-checkbox hide-details="true" v-model="currentObj.setcommon" class="ma-0 pa-0"><template v-slot:label><span id="checkboxLabel" class="text-caption">Set as Common</span></template></v-checkbox>
                                          </v-col>
                                          <v-col md="3" class="pa-0" align="right">
                                            <v-checkbox hide-details="true" v-model="currentObj.setna" class="ma-0 pa-0"><template v-slot:label><span id="checkboxLabel" class="text-caption">Set as N/A</span></template></v-checkbox>
                                          </v-col>
                                      </v-row>
                                       <v-row class="pa-0 ma-0 pt-4 pl-3" align="center">
                                          <v-col md="3" class="pa-0">
                                            <v-checkbox hide-details="true" v-model="currentObj.assessorcomplete" class="ma-0 pa-0"><template v-slot:label><span id="checkboxLabel" class="text-caption">Assessor Complete</span></template></v-checkbox>
                                          </v-col>
                                          <v-col md="3" class="pa-0">
                                            <v-checkbox hide-details="true" v-model="currentObj.setcap" class="ma-0 pa-0"><template v-slot:label><span id="checkboxLabel" class="text-caption">Set as CAP</span></template></v-checkbox>
                                          </v-col>
                                          <v-col md="3" class="pa-0">
                                            <v-checkbox hide-details="true" v-model="currentObj.inherited" class="ma-0 pa-0"><template v-slot:label><span id="checkboxLabel" class="text-caption">Inherited</span></template></v-checkbox>
                                          </v-col>                                            
                                      </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-row>


                    </v-container>
                </v-window-item>
                <!-- PROCESS TAB -->
                <v-window-item style="overflow: auto; height: calc(100vh - 215px);" class="mt-2" v-if="projectType != 'i1'" value="process">

            
                        <v-container class="py-5">




                        <v-row>
                            <v-col md="12">
                                <v-card color="#0969ab87" theme="dark">
                                    <v-card-title class="text-button"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Requirement</v-card-title>
                                        <v-card-subtitle>
                                        {{ currentObj.req }}
                                        </v-card-subtitle>
                                    <v-card-actions>

                                    </v-card-actions>
                                </v-card>
                            </v-col>

                        </v-row><v-row>

                            <v-col md="12" v-if="showpip">
                                <v-card color="#3d9e3dcf" theme="dark">
                                        <v-card-subtitle>
                                        {{ currentObj.illpro }}
                                        </v-card-subtitle>
                                </v-card>
                            </v-col>                        
                            
                        </v-row><v-row>

                            <v-col md="12" v-if="showscoring">
                                <v-card color="#434f57cf" theme="dark">
                                  <v-card-title class="text-button"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Process Scoring</v-card-title>
                                  <v-card-subtitle>
                                    Calculated score:  {{ currentProcessScore }} %
                                  </v-card-subtitle>
                                        <v-card-text>
                                          <v-list flat>
                                              <v-list-item v-for="(item,index) in currentObj.elements" :key="'item-' + index">

                                                  <v-list-item-action>
                                                    <v-checkbox v-model="item.procsatisfied" color="green lighten-1"></v-checkbox>
                                                  </v-list-item-action>

    
                                                  <span class="text-caption">{{ item.text }}</span>


                                              </v-list-item>
                                          </v-list>

                                          <v-slider v-model="currentObj.proctierscore" :tick-labels="ticksLabels" :max="2" step="1" ticks="always" tick-size="8" color="orange"></v-slider>

                                          <v-row>
                                            <v-col md="4" align="left">
                                              No process
                                            </v-col>

                                            <v-col md="4" align="center">
                                              Undocumented process
                                            </v-col>

                                            <v-col md="4" align="right">
                                              Documented process
                                            </v-col>
                                          </v-row>                                                                                   
                                      </v-card-text>

                                    <v-card-actions>                                      
                                        <v-spacer></v-spacer>
                                    </v-card-actions>

                                </v-card>
                            </v-col>

                          </v-row><v-row>

                            <v-col md="12">
                                <v-card color="#0969abcf" theme="dark">
                                    <v-card-title class="text-button"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Process Language<v-spacer></v-spacer><v-btn icon @click="refreshProcess()"><v-icon>mdi-arrow-u-left-top</v-icon></v-btn></v-card-title>
                                    <v-card-subtitle>Populate the language from your process which meets the requirement.</v-card-subtitle>
                                    <v-card-text>
                                        <v-textarea v-model="currentObj.proctxt" solo light></v-textarea>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-row><v-row>

                            <v-col md="12">
                                <v-card color="#ffffffcf" @drop="drop($event, {drop: 'procass'})" @dragover.prevent>
                                  <v-card-title class="text-button"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Associated Processes</v-card-title>
                                  <v-card-subtitle>
                                    Select the associated process files and page number
                                  </v-card-subtitle>
                                    <v-card-text>
                                      <v-data-table
                                        :headers="headers"
                                        :items="associatedProcessCompute"
                                        :items-per-page="-1"
                                        :loading="tableLoading"
                                        loading-text="Loading... Please wait"                                             
                                        hide-default-footer
                                        class="elevation-1"
                                      >
                                        <template v-slot:item.name="{ item }">
                                          <a :href="item.url" target="_blank">{{ item.name }}</a>
                                        </template>

                                        <template v-slot:item.actions="{ item }">
                                          <v-icon small @click="remProcessAssociation(item)"> mdi-delete </v-icon>
                                        </template>

                                        <template v-slot:item.page="{ item }">
                                          <input style="outline: 1px solid #0000002b" size="4" v-model="associatedProcessComputeObj[item.id].processpage[currentUID]" outlined dense hide-details="true">
                                        </template>


                                      </v-data-table>                                                                                 
                                    </v-card-text>

                                    <v-card-actions>                                      
                                        <v-spacer></v-spacer>

                                    </v-card-actions>

                                </v-card>
                            </v-col>

                        </v-row>
                       <v-row>

                            <v-col md="12">
                                <v-card color="#ffffffcf" id="filescard"  @drop="drop($event)" @dragenter="dragging = 'elevation-1 green lighten-4'" @dragend="dragging='elevation-1'" @dragleave="dragging='elevation-1'" @dragover.prevent>
                                  <v-card-title class="text-button"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Document Repository</v-card-title>
                                  <v-card-subtitle>
                                    Upload or delete policy and process files which will be used to associate with a requirement. You can drag and drop multiple files on this panel to upload.
                                  </v-card-subtitle>
                                    <v-card-text>
                                      <v-data-table                                        
                                        show-select
                                        fixed-header
                                        :height="allDocsCompute.length > 8 ? 32 * 10 : undefined"
                                        :headers="procfileListHeaders"
                                        :items="allDocsCompute"
                                        :items-per-page="5"
                                        :search="fileSearch"
                                        :loading="tableLoading"
                                        loading-text="Loading... Please wait"                                             
                                        v-model="filesSelected"                                        
                                        class="elevation-1"
                                      >
                                        <template v-slot:top>
                                          <div style="display: flex; flex-wrap: wrap;">
                                          <v-text-field v-model="fileSearch" label="Search" dense clearable hide-details class="pa-6"></v-text-field>
                                          <v-btn @click="addMultiProcessAssociation()" small color="green" class="ma-2 mt-4 white--text"><v-icon left>mdi-plus</v-icon>Process</v-btn>
                                          <v-btn @click="deleteMultiFiles()" small color="red" class="ma-2 mt-4 white--text">Delete<v-icon right>mdi-delete</v-icon></v-btn>
                                          </div>
                                        </template>                                      

                                        <template v-slot:item.name="{ item }">
                                          <a :href="item.url" target="_blank">{{ item.name }}</a>&nbsp;
                                          <v-tooltip bottom  v-if="item.datediff > 90">
                                              <template v-slot:activator="{ props }">                                              
                                                 <v-icon theme="dark" small v-bind="props" color="red">mdi-alert-circle</v-icon>
                                              </template>
                                              <span>Evidence out of date</span>
                                          </v-tooltip>  
                                        </template>

                                        <template v-slot:item.actions="{ item }">
                                          <v-icon small @click="deleteFile(item)"> mdi-delete </v-icon>
                                        </template>

                                        <template v-slot:item.addprocess="{ item }">
                                          <v-icon small @click="addProcessAssociation(item)"> mdi-pencil-plus </v-icon>
                                        </template>
                                        
                                      </v-data-table>                                                                                 
                                    </v-card-text>

                                    <v-card-actions>
                                      <v-row align="center" class="pb-3">
                                        <v-col md="12">
                                          <div style="display: flex; flex-wrap: wrap;">
                                          <v-file-input label="File input" dense v-model="$store.state.chosenFile" hide-details="true" class="text-caption">
                                            <template v-slot:label>
                                              <span class="text-caption">File Input</span>
                                            </template>                                            
                                          </v-file-input>
                                          &nbsp;&nbsp;
                                          <v-btn small @click="upFile($store.state.chosenFile)">Upload</v-btn>
                                          </div>                                                                  
                                        </v-col>

                                      </v-row> 
                                    </v-card-actions>

                                </v-card>
                            </v-col>

                        </v-row>                          
                        <v-row>

                            <v-col md="12" v-if="showgaps">
                                <v-card color="#c02727cf" theme="dark">
                                    <v-card-title class="text-button"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Process Gaps</v-card-title>
                                    <v-card-subtitle>Gaps that have been identified related to your submitted policies.</v-card-subtitle>
                                    <v-card-text>
                                        <v-textarea v-model="currentObj.procgaps" solo light></v-textarea>
                                    </v-card-text>
                                    <v-card-actions right>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                        </v-row><v-row>

                            <v-col md="12" v-if="showtresults">
                                <v-card color="#0969ab87" theme="dark">
                                    <v-card-title class="text-button"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Test Results</v-card-title>
                                    <v-card-subtitle>{{ testResults }}</v-card-subtitle>
                                </v-card>
                            </v-col>

                        </v-row><v-row>

                            <v-col md="12" v-if="showoptions">
                                <v-card color="#3d9e3dcf" theme="dark">
                                    <v-card-title class="text-button"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Options</v-card-title>
                                    <v-card-subtitle>Options for completion of this requirement</v-card-subtitle>
                                    <v-card-text>
                                      <v-row class="pa-0 ma-0 pt-2 pl-3" align="center">
                                          <v-col md="3" class="pa-0">
                                            <v-checkbox hide-details="true" v-model="currentObj.clientcomplete" class="ma-0 pa-0"><template v-slot:label><span id="checkboxLabel" class="text-caption">Client Complete</span></template></v-checkbox>
                                          </v-col>
                                          <v-col md="3" class="pa-0">
                                            <v-checkbox hide-details="true" v-model="currentObj.setcommon" class="ma-0 pa-0"><template v-slot:label><span id="checkboxLabel" class="text-caption">Set as Common</span></template></v-checkbox>
                                          </v-col>
                                          <v-col md="3" class="pa-0" align="right">
                                            <v-checkbox hide-details="true" v-model="currentObj.setna" class="ma-0 pa-0"><template v-slot:label><span id="checkboxLabel" class="text-caption">Set as N/A</span></template></v-checkbox>
                                          </v-col>
                                      </v-row>
                                       <v-row class="pa-0 ma-0 pt-4 pl-3" align="center">
                                          <v-col md="3" class="pa-0">
                                            <v-checkbox hide-details="true" v-model="currentObj.assessorcomplete" class="ma-0 pa-0"><template v-slot:label><span id="checkboxLabel" class="text-caption">Assessor Complete</span></template></v-checkbox>
                                          </v-col>
                                          <v-col md="3" class="pa-0">
                                            <v-checkbox hide-details="true" v-model="currentObj.setcap" class="ma-0 pa-0"><template v-slot:label><span id="checkboxLabel" class="text-caption">Set as CAP</span></template></v-checkbox>
                                          </v-col>
                                          <v-col md="3" class="pa-0">
                                            <v-checkbox hide-details="true" v-model="currentObj.inherited" class="ma-0 pa-0"><template v-slot:label><span id="checkboxLabel" class="text-caption">Inherited</span></template></v-checkbox>
                                          </v-col>                                          
                                      </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-row>


                    </v-container>
                </v-window-item>
        <!-- IMPLEMENTED TAB -->
                <v-window-item style="overflow: auto; height: calc(100vh - 213px);" class="mt-0"  value="implemented">

                      <v-container class="py-5">
                      <v-expansion-panels v-model="implPanels" multiple style="width: 100%">
                      <draggable v-model="implCards" item-key="id" handle=".handle" style="width: 100%">
                      <template #item="{element}">
                      
                        <v-row>

                          <!-- REQUIREMENTS CARD -->
                            <v-col md="12" class="pa-1" v-if="element.id == 1">


                                <v-expansion-panel bg-color="#0969ab87" value="REQ" elevation="5">
                                  <v-expansion-panel-title class="pa-2">
                                    <span class="text-button text-white"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Requirement</span>
                                  </v-expansion-panel-title>
                                  <v-expansion-panel-text class="expansion-text">
                                    {{ currentObj.req }}
                                  </v-expansion-panel-text>
                                </v-expansion-panel>


                            </v-col>

                            <!-- ILLUSTRATIVE PROCEDURES CARD -->
                            <v-col md="12" class="pa-1" v-if="implShowCards.showpip && element.id == 2">

                                <v-expansion-panel bg-color="#3d9e3dcf" value="ILLPRO" elevation="5">
                                  <v-expansion-panel-title class="pa-2">
                                    <span class="text-button text-white"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Illustrative Procedures</span>
                                  </v-expansion-panel-title>
                                  <v-expansion-panel-text class="expansion-text">
                                    {{ currentObj.illimpl }}
                                  </v-expansion-panel-text>
                                </v-expansion-panel>


                            </v-col>           

                            <!-- SCORING CARD -->
                            <v-col md="12" class="pa-1" v-if="implShowCards.showscoring && element.id == 3">

                                <v-expansion-panel bg-color="#434f57cf" value="IMPLSCOR" elevation="5">
                                  <v-expansion-panel-title class="pa-2">
                                    <span class="text-button text-white"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Implementation Scoring</span>
                                  </v-expansion-panel-title>
                                  <v-expansion-panel-text class="expansion-text">
                                    <div style="display: flex; flex-wrap: wrap; align-items: center;">
                                      <span class="text-overline pt-4">score:</span> &nbsp;&nbsp;
                                      <v-select hide-details variant="plain" :readonly="currentObj.imploverride ? false : true" v-model="currentObj.implscore" :items="scoreItems" chips style="flex: none; width: 75px;"></v-select>
                                    </div>
                                    {{ implscoring }}
                                    <v-switch hide-details @click="calcImplScore()" v-model="currentObj.imploverride" dense color="red" class="pl-2 mt-0"><template v-slot:label><span class="text-body-2">Override</span></template></v-switch>
                                  </v-expansion-panel-text>
                                </v-expansion-panel>

                            </v-col>

                            <!-- TESTING NARRATIVE CARD -->
                            <v-col md="12" class="pa-1"  v-if="element.id == 4">

                                <v-expansion-panel bg-color="#0969abcf" value="NARR" elevation="5">
                                  <v-expansion-panel-title class="pa-2">
                                    <span class="text-button text-white"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Testing Narrative</span>
                                  </v-expansion-panel-title>
                                  <v-expansion-panel-text>
                                    <span class="expansion-text expansion-pad">Populate the results of testing the implementation of this requirement.</span>
                                    <v-textarea v-model="currentObj.testnarrative" variant="solo" theme="light"></v-textarea>
                                  </v-expansion-panel-text>
                                </v-expansion-panel>

                            </v-col>                            

                            <!-- EVIDENCE REQUESTS CARD -->
                            <v-col md="12" class="pa-1" v-if="element.id == 5">
                     
                                <v-expansion-panel bg-color="#ffffffcf" value="EVREQ" elevation="5">
                                  <v-expansion-panel-title class="pa-2">
                                    <span class="text-button"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Evidence Requests</span>
                                  </v-expansion-panel-title>
                                  <v-expansion-panel-text>
                                    <span class="expansion-text text-black">The following evidence requests are associated with this requirement.</span><br><br>
                                      <v-data-table
                                        :headers="evidenceHeaders"
                                        :items="evidenceList"
                                        :items-per-page="-1"
                                        :row-props="ev_row_classes"
                                        :loading="tableLoading"
                                        loading-text="Loading... Please wait"                                             
                                        class="elevation-1"
                                      >
   

                                        <template v-slot:item.key="{ item }">
                                          <div v-if="item.type == 'ev'" style="display: flex; align-items: end; justify-content: flex-start;">
                                            <v-icon color="#8cd0ff" class="handle">mdi-arrow-right-bottom</v-icon>&nbsp;
                                            <a v-if="item.type == 'ev'" @click="openEvidence(item.key)">{{ item.key }}</a>
                                          </div>
                                          <div v-else style="display: flex;">
                                            <v-chip>{{ item.key }}</v-chip>                                            
                                          </div>
                                        </template>
                                        <template v-slot:item.score="{ item }">
                                          <div v-if="item.type != 'ev'">
                                          <v-chip small theme="dark" class="white--text" color="#0969abcf">{{ item.score === "NA" ? "NA" : Math.round(item.score)  }}</v-chip>
                                          </div>
                                          <div v-else style="display: flex; align-items: end; justify-content: flex-end;">
                                            <v-chip variant="flat" small theme="dark" class="black--text" color="#d5d1d1">{{  item.score === "NA" ? "NA" : Math.round(item.score)  }}</v-chip>
                                            <v-icon color="#8cd0ff" class="handle">mdi-arrow-up-right</v-icon>
                                          </div>
                                        </template>
                                        <template v-slot:bottom> </template>
                                      </v-data-table>   
                                  </v-expansion-panel-text>
                                </v-expansion-panel>

                            </v-col>

                            <!-- IMPLEMENTATION GAPS CARD -->
                            <v-col md="12" class="pa-1" v-if="implShowCards.showgaps && element.id == 6">

                                <v-expansion-panel bg-color="#c02727cf" value="IMPLGAPS" elevation="5">
                                  <v-expansion-panel-title class="pa-2">
                                    <span class="text-button text-white"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Implementation Gaps</span>
                                  </v-expansion-panel-title>
                                  <v-expansion-panel-text>
                                    <span class="expansion-text expansion-pad">Gaps that have been identified related to your submitted policies.</span>
                                    <v-textarea v-model="currentObj.implgaps" variant="solo" theme="light"></v-textarea>
                                  </v-expansion-panel-text>
                                </v-expansion-panel>

                            </v-col>

                            <!-- TEST RESULTS CARD -->
                            <v-col md="12" class="pa-1" v-if="implShowCards.showtresults && element.id ==7">

                                <v-expansion-panel bg-color="#0969ab87" value="TESTRESULTS" elevation="5">
                                  <v-expansion-panel-title class="pa-2">
                                    <span class="text-button text-white"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Test Results</span>
                                  </v-expansion-panel-title>
                                  <v-expansion-panel-text class="expansion-text">
                                    {{ testResults }}
                                  </v-expansion-panel-text>
                                </v-expansion-panel>

                            </v-col>

                            <!-- OPTIONS CARD -->
                            <v-col md="12" class="pa-1" v-if="implShowCards.showoptions && element.id ==8">

                                <v-expansion-panel bg-color="#3d9e3dcf" value="ILLOPTS" elevation="5">
                                  <v-expansion-panel-title class="pa-2">
                                    <span class="text-button text-white"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Options</span>
                                  </v-expansion-panel-title>
                                  <v-expansion-panel-text class="text-white">                                    
                                    <span class="expansion-text">Options for completion of this requirement</span><br>
                                    <v-row class="pa-0 ma-0 pt-2 pl-3" align="center">
                                          <v-col md="3" class="pa-0">
                                            <v-checkbox hide-details="true" v-model="currentObj.clientcomplete" class="ma-0 pa-0"><template v-slot:label><span id="checkboxLabel" class="text-caption">Client Complete</span></template></v-checkbox>
                                          </v-col>
                                          <v-col md="3" class="pa-0">
                                            <v-checkbox hide-details="true" v-model="currentObj.setcommon" class="ma-0 pa-0"><template v-slot:label><span id="checkboxLabel" class="text-caption">Set as Common</span></template></v-checkbox>
                                          </v-col>
                                          <v-col md="3" class="pa-0" align="right">
                                            <v-checkbox hide-details="true" v-model="currentObj.setna" class="ma-0 pa-0"><template v-slot:label><span id="checkboxLabel" class="text-caption">Set as N/A</span></template></v-checkbox>
                                          </v-col>                                        
                                      </v-row>
                                       <v-row class="pa-0 ma-0 pt-4 pl-3" align="center">
                                          <v-col md="3" class="pa-0">
                                            <v-checkbox hide-details="true" v-model="currentObj.assessorcomplete" class="ma-0 pa-0"><template v-slot:label><span id="checkboxLabel" class="text-caption">Assessor Complete</span></template></v-checkbox>
                                          </v-col>
                                          <v-col md="3" class="pa-0">
                                            <v-checkbox hide-details="true" v-model="currentObj.setcap" class="ma-0 pa-0"><template v-slot:label><span id="checkboxLabel" class="text-caption">Set as CAP</span></template></v-checkbox>
                                          </v-col>
                                          <v-col md="3" class="pa-0">
                                            <v-checkbox hide-details="true" v-model="currentObj.inherited" class="ma-0 pa-0"><template v-slot:label><span id="checkboxLabel" class="text-caption">Inherited</span></template></v-checkbox>
                                          </v-col>                                            
                                      </v-row>
                                      <v-row class="pa-0 ma-0 pt-2 pl-3" align="center">
                                          <v-col md="12" class="pa-0">
                                            <span class="text-overline">Implementation Specific</span>
                                          </v-col>
                                      </v-row>
                                      <v-row class="pa-0 ma-0 pt-2 pl-3" align="center">
                                          <v-col md="3" class="pa-0" align="right">
                                            <v-checkbox hide-details="true" v-model="currentObj.usepolicy" class="ma-0 pa-0"><template v-slot:label><span id="checkboxLabel" class="text-caption">Use Policy as Evidence</span></template></v-checkbox>
                                          </v-col>  
                                      </v-row>

                                  </v-expansion-panel-text>
                                </v-expansion-panel>

                            </v-col>


                        </v-row>
                      </template>
                      </draggable>
                    </v-expansion-panels>

                    </v-container>
                </v-window-item>                
                <!-- CHAT TAB -->
                <v-window-item style="overflow: auto; height: calc(100vh - 157px);" class="mt-2" value="chat">
                      <v-container class="py-5">
                        <v-row>
                            <v-col md="12">
                                <v-card color="white">
                                    <v-card-title class="text-button">Chat</v-card-title>
                                      <v-card-subtitle>Ask questions about this requirement.</v-card-subtitle>

                                    <v-card-text style="overflow: auto; height: calc(100vh - 350px); display: flex; flex-direction: column-reverse;">
                                      <v-container>
                                        <v-row align="center" :justify="chatAlign(item.userid)" v-for="(item, index) in chatdata" :key="index" class="ma-0">
                                          <v-col md="2" v-if="nameMatches(item.userid)">
                                            <span class="white--text text-h6"><v-avatar color="red" size="48">{{ item.user.charAt(0) }}</v-avatar></span>
                                          </v-col>
                                          <v-col md="10" class="pa-1" :align="chattextAlign(item.userid)">
                                            <div>&nbsp;&nbsp;<span class="text-caption">{{ item.user }}</span></div>
                                            <div class="pa-4 rounded-xl black--text" :style="'display: inline-block; ' + chatColor(item.userid)">{{ item.text }}</div>
                                            <div>&nbsp;&nbsp;<span class="text-caption">{{ item.datetime.toDate().toDateString() + " " + item.datetime.toDate().toLocaleTimeString('en-US') }}</span></div>
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                    </v-card-text>
                                  <v-form ref="chatUserForm" @submit.prevent="submitChat()" lazy-validation>
                                    <v-card-actions>                                      
                                      <v-row align="center">
                                        <v-col md="12">
                                          <div style="display: flex;">
                                            <v-text-field v-model="chattext" label="Chat" variant="solo" density="compact" hide-details single-line :rules="textRules" ></v-text-field>                                      
                                            <v-btn class="mx-2" theme="dark" color="primary" type="submit">Send <v-icon theme="dark" right>mdi-send</v-icon></v-btn>
                                          </div>
                                        </v-col>
                                      </v-row>
                                    </v-card-actions>
                                  </v-form>
                                </v-card>
                            </v-col>
                        </v-row>
                      </v-container>
                </v-window-item>

            </v-window>

    <div style="background-color: #2e2e2e; display: flex; flex-wrap: wrap; height: 45px;" class="pt-2">  
        <v-container class="pa-0">
            <v-row class="pa-0 ma-0 pt-0 pl-3" align="center">
                <v-col md="8" class="pa-0">
                  <div style="display: flex;">
                    <v-btn-toggle variant="outlined" theme="dark" density="compact" style="height: 30px; background-color: #4b4e52;" multiple :v-model="[0, 1, 2]">
                        <v-btn size="small" icon="mdi-image-size-select-small" class="pl-8 pr-8" @click="changeWidth('sm')"></v-btn>
                        <v-btn size="small" icon="mdi-image-size-select-large" class="pl-8 pr-8" @click="changeWidth('med')"></v-btn>
                        <v-btn size="small" icon="mdi-image-size-select-actual" class="pl-8 pr-8" @click="changeWidth('lrg')"></v-btn>
                    </v-btn-toggle>
                    &nbsp;&nbsp;
                    <v-btn-toggle variant="outlined" theme="dark" density="compact" style="height: 30px; background-color: #4b4e52;" multiple :v-model="[0, 1, 2]">
                        <v-btn size="small" icon="mdi-menu-left" class="pl-8 pr-8" @click="goBack()"></v-btn>
                        <v-btn size="small" icon="mdi-menu-right" class="pl-8 pr-8" @click="goForward()"></v-btn>
                    </v-btn-toggle>
                  </div>
                </v-col>
                <v-col md="4" class="pa-0" align="right" style="height: 30px;">
                    <v-btn variant="text" class="pl-8 pr-8 no-active" style="height: 30px;" prepend-icon="mdi-content-save" @click="saveRequirement()" :loading="saving" :disabled="saving">
                        <span>Save</span>
                    </v-btn>
                </v-col>
              </v-row>
        </v-container>
      </div>

        </v-navigation-drawer>

  <confirmation ref="confdlg"></confirmation>

  <v-dialog v-model="imgPreview" max-width="500">
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="ma-0 pa-0 pt-2">
              <v-img max-width="500" :src="this.pasteFileURL"></v-img>
            </v-col>
          </v-row>
          </v-container>
      </v-card-text>
      <v-card-actions>        
        <v-text-field v-model="pasteFileName" color="primary" label="File Name" required hide-details></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="blue" text @click="imgPreview = false">Close</v-btn>
        <v-btn color="blue" text @click="uploadImg(pasteFile); imgPreview = false">Upload</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  </div>
</template>

<script>
import Confirmation from '@/components/Confirmation.vue'
import tooltip from '@/components/Tooltip.vue'
import { getFirestore, collection, getDocs, query, where, onSnapshot, addDoc, orderBy, Timestamp, limit, deleteDoc, doc, updateDoc, arrayUnion, arrayRemove, setDoc, writeBatch  } from "firebase/firestore";
import { getStorage, ref, listAll, getMetadata  } from "firebase/storage";
import db from "@/main.js";
import draggable from 'vuedraggable'
//import { AgGridVue } from "ag-grid-vue";

export default {
  name: 'RightNav',
  props: {
      showhide: Boolean,
      },
  data() {
    return {
        tab: null,
        draggedRow: null,
        implShowCards: {showpip: false, showscoring: false, showgaps: false, showtresults: false, showoptions: false},
        rubric: false,
        dragging: "elevation-1",
        currentObj: {poltxt: "Inital", elements: ["Inital"], tierscore: "0", polgaps: ""},
        navigation: {
            shown: false,
            width: 500,
            borderSize: 3,
        },
        ticksLabels: [
            'Tier 0',
            'Tier 1',
            'Tier 2',
          ],
        chatdata: [],
        chattext: null,
        textRules: [
            v => !!v || 'Text is required',
          ],
        unsubscribeChat: null,
        unsubscribeFiles: null,
        unsubscribePolicyFiles: null,
        unsubscribeProcessFiles: null,
        columnDefs: null,
        rowData: null,
        rowSelection: null,
        gridApi: null,
        columnApi: null,
        headers: [
          { text: 'File Name', align: 'start', value: 'name' },
          { text: 'Uploaded By', value: 'uploadedby.useremail' },
          { text: 'Action', align: 'center', value: 'actions', width: "100px" },
          { text: 'Page #', value: 'page', width: "100px" }
        ],
        fileListHeaders: [
          { text: 'File Name', align: 'start', value: 'name' },
          { text: 'Uploaded By', value: 'uploadedby.useremail' },
          { text: 'Time', value: 'dtime' },
          { text: 'Action', align: 'center', value: 'actions', width: "100px" },
          { text: 'Policy', align: 'center', value: 'addpolicy', width: "100px" },
          { text: 'Process', align: 'center', value: 'addprocess', width: "100px" },

        ],
        polfileListHeaders: [
          { text: 'File Name', align: 'start', value: 'name' },
          { text: 'Uploaded By', value: 'uploadedby.useremail' },
          { text: 'Time', value: 'dtime' },
          { text: 'Action', align: 'center', value: 'actions', width: "100px" },
          { text: 'Policy', align: 'center', value: 'addpolicy', width: "100px" },     
        ],
        procfileListHeaders: [
          { text: 'File Name', align: 'start', value: 'name' },
          { text: 'Uploaded By', value: 'uploadedby.useremail' },
          { text: 'Time', value: 'dtime' },
          { text: 'Action', align: 'center', value: 'actions', width: "100px" },
          { text: 'Process', align: 'center', value: 'addprocess', width: "100px" },          
        ],
        evidenceHeaders: [
          { title: 'Evidence', align: 'start', key: 'key' },
          { title: 'Request', key: 'request' },
          { title: 'Score', align: 'center', key: 'score', width: "100px" },
        ],
        scoreItems:[0,25,50,75,100],
        chosenFile: null,
        fileList: [],
        filesSelected: [],
        policyFileObj: {},
        policyFileList: [],
        processFileObj: {},
        processFileList: [],
        evidenceList: [],
        dragitems: null,
        saving: false,
        batchwrite: null,
        evidenceWin: null,
        imgPreview: false,
        pasteFile: null,
        pasteFileName: null,
        pasteFileURL: null,
        fileSearch: null,
        tableLoading: false,
        sideLoading: false,
        implCards: [
                { id: 1, name: 'Requirement' },
                { id: 2, name: 'Illustrative Procedures' },
                { id: 3, name: 'Implementation Scoring' },
                { id: 4, name: 'Testing Narrative' },
                { id: 5, name: 'Evidence Requests' },
                { id: 6, name: 'Implementation Gaps' },
                { id: 7, name: 'Test Results' },
                { id: 8, name: 'Options' },
            ],
        implPanels: ["EVREQ", "NARR", "REQ", "IMPLSCOR", "IMPLGAPS", "ILLOPTS", "ILLPRO", "TESTRESULTS"],
        localShowHide: this.showhide,
        
    }
  },

  components: {
    Confirmation,
    draggable,
    tooltip,
    },

  methods: {
    async test(){
      let totalScore = 0;
      let count = 0;
      let hundycount = 0;

      Object.keys(this.currentObj.elements).forEach(key => {
        if (this.currentObj.elements[key].score !== "NA" && !isNaN(this.currentObj.elements[key].score)) {
          console.log("element: ", key, ": ", this.currentObj.elements[key])
          totalScore += parseInt(this.currentObj.elements[key].score);
          count++;
          if (this.currentObj.elements[key].score == 100) {
            hundycount++;
          }
        } else {console.log("not true: ", key)}
      });

      let finalScore = count === 0 ? 0 : Math.round(totalScore / count);     

      console.log( `FD examined evidence to confirm that ${hundycount} of the ${count} evaluative elements were satisfied. The overall score is ${finalScore}%.`)
  },
    saveUserPrefs() {
      this.$store.state.user.prefs.implPanels = this.implPanels;
      this.$store.state.user.prefs.implCards = this.implCards;
      this.$store.state.user.prefs.implShowCards = this.implShowCards;

      this.$store.dispatch('saveUserPrefs', this.$store.state.user.prefs);
    },

    loadUserPrefs() {
      if (this.$store.state.user && this.$store.state.user.prefs) {
        if (this.$store.state.user.prefs.implPanels != null) {
          this.implPanels = this.$store.state.user.prefs.implPanels;
        }
        if (this.$store.state.user.prefs.implCards != null) {
          this.implCards = this.$store.state.user.prefs.implCards;
        }
        if (this.$store.state.user.prefs.implShowCards != null) {
          this.implShowCards = this.$store.state.user.prefs.implShowCards;
        }
    }
    },

    ev_row_classes(item) {
        if (item.item.type == "ev") {
          return { class: "ev-row" }; // Return an object with a 'class' attribute
        } 
    },    
    previewImg(file){
      //this.pasteFile = JSON.parse(JSON.stringify(file));
      this.pasteFile = file;
      this.pasteFileName = Date.now() + "-screen-shot.png"
      this.pasteFileURL = URL.createObjectURL(file)
      this.imgPreview = true
    },
    uploadImg(file){
      const newfile = new File([file], this.pasteFileName, 
             {
                 type: file.type,
                 lastModified: file.lastModified
             }
         )
      console.log(newfile)
      this.upFile(newfile)
    },
    onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;                         
            },
    //Menu sizing --->

    setEvents() {
      //const minSize = this.navigation.borderSize;
      const minSize = 10; // Set your minimum size here
      //const el = this.$refs.drawer.$el.nextSibling;
      const el = document.getElementById("rightDrawer");
      const drawerBorder = this.$refs.border;

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f = window.innerWidth - e.clientX;
        el.style.width = f + "px";
      }

      drawerBorder.addEventListener(
        "mousedown",
        (e) => {          
          e.preventDefault();
          if (e.offsetX < minSize) {
            el.style.transition = "initial";
            document.addEventListener("mousemove", resize, false);
          }
        },
        false
      );

      drawerBorder.addEventListener(
        "dblclick",
        (e) => {
          this.navigation.width = 1000
        },
        false
      );

      document.addEventListener(
        "mouseup",
        () => {
          el.style.transition = "";
          this.navigation.width = parseInt(el.style.width, 10);
          this.$store.commit('changedocuRMenuWidth', { width: this.navigation.width });
          document.body.style.cursor = "";
          document.removeEventListener("mousemove", resize, false);
        },
        false
      );
    //End menu sizing ---->

      document.onpaste = (evt) => {
        const dT = evt.clipboardData || window.clipboardData;
        const file = dT.files[ 0 ];
        console.log(file)
        if( file == undefined || file.type != "image/png" ){ return } //ensure file is an image
        
        let cpath = evt.composedPath()
        cpath.forEach((element) => { 
          if (element.id == 'filescard'){ 
            this.previewImg(file)
            console.log("yes found", file)
            }
        })        
      };

      document.onkeyup = (e) => {
      //if(e.shiftKey == true && e.ctrlKey == true) {console.log(e)}
      if(e.shiftKey == true && e.ctrlKey == true && e.key == "S"){ this.saveRequirement() }        
      if(e.shiftKey == true && e.ctrlKey == true && e.key == "U"){ this.goForward() } 
      if(e.shiftKey == true && e.ctrlKey == true && e.key == "Y"){ this.goBack() } 
      /*
      Available Keys
      Ctrl + Shift + L
      Ctrl + Shift + O
      Ctrl + Shift + S
      Ctrl + Shift + U
      Ctrl + Shift + Y
      */
    };

    },
    changeWidth(size){
      if (size == "sm"){this.navigation.width = (window.innerWidth / 4)}
      if (size == "med"){this.navigation.width = (window.innerWidth / 2)}
      if (size == "lrg"){this.navigation.width = (window.innerWidth / 1.5)}
      this.$store.commit('changedocuRMenuWidth', { width: this.navigation.width });
      return
    },
    updateCurrentObj(value){
      //this.currentObj.policyfiles.push({name: "blah.txt", uploader: "john", page: "77"})
      console.log(value);
    },
    startChat(){
      if (this.unsubscribeChat != null){console.log("Unsubbed"); this.unsubscribeChat();}
      this.chatdata = [];
      console.log("Initiated Start Chat")
      const q = query(collection(db, "projects/" + this.$parent.$parent.currentProject + "/chat"), where("ref", "==", this.$parent.$parent.currentUID), orderBy("datetime"), limit(20));
      this.unsubscribeChat = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {

          if (change.type === "added") {
            this.chatdata.push(change.doc.data());
            //console.log("Added:", change.doc.data())
          }
          if (change.type === "modified") {
            //Change
          }
          if (change.type === "removed") {
            //Removed
          }
        });

      });

    },
    async submitChat() {
        if(!this.$refs.chatUserForm.validate()){return} //Exit the function if form is not validated
        try {        
        let data = {datetime: Timestamp.now(), ref: this.$parent.$parent.currentUID, text: this.chattext, user: this.$store.state.user.name, userid: this.$store.state.user.id}
        const docRef = await addDoc(collection(db, "projects/" + this.$parent.$parent.currentProject + "/chat"), data);
        console.log("Document written with ID: ", docRef.id);
        this.chattext = "";
 
          } catch(e) {
            this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });
            console.error(e);
            } 
    },
    nameMatches(value){
      if (this.$store.state.user.id == value){ return false } else { return true }      
    },
    chatAlign(value){
      if (this.$store.state.user.id == value){ return "end" } else { return "start" }      
    },
    chattextAlign(value){
      if (this.$store.state.user.id == value){ return "right" } else { return "left" }      
    },
    chatColor(value){
      if (this.$store.state.user.id == value){ return "background-color: #c9e1fe" } else { return "background-color: #e5e5e5" }      
    },
    drop(ev, item) {
      ev.preventDefault();
      console.log(ev);
      if (ev.dataTransfer.items) {
        console.log("All items: ", ev.dataTransfer.items)
        // Use DataTransferItemList interface to access the file(s)
        ev.dataTransfer.items.forEach((element, i) => {  
          if (element.kind === 'file') {
            
            if(item != undefined && (item.drop == 'polass' || item.drop == 'procass')){this.upFile(element.getAsFile(), item); return }
            this.upFile(element.getAsFile())

          }
        })
      } else {
        // Use DataTransfer interface to access the file(s)
         ev.dataTransfer.files.forEach((element, x) => { 
          //console.log('2... file[' + x + '].name = ' + element.name);
        })
      }
    },

    LevenshteinDistance(a, b){
      a = a.trim().replaceAll("\\s+", " ").replace(/[^\w\s]|_/g, "").toLowerCase();
      b = b.trim().replaceAll("\\s+", " ").replace(/[^\w\s]|_/g, "").toLowerCase();
      if(a.length == 0) return 0; 
      if(b.length == 0) return 0; 

      var matrix = [];

      // increment along the first column of each row
      var i;
      for(i = 0; i <= b.length; i++){
          matrix[i] = [i];
      }

      // increment each column in the first row
      var j;
      for(j = 0; j <= a.length; j++){
          matrix[0][j] = j;
      }

      // Fill in the rest of the matrix
      for(i = 1; i <= b.length; i++){
          for(j = 1; j <= a.length; j++){
          if(b.charAt(i-1) == a.charAt(j-1)){
              matrix[i][j] = matrix[i-1][j-1];
          } else {
              matrix[i][j] = Math.min(matrix[i-1][j-1] + 1, // substitution
                                      Math.min(matrix[i][j-1] + 1, // insertion
                                              matrix[i-1][j] + 1)); // deletion
          }
          }
      }

      let percentage = (100 - (matrix[b.length][a.length]) * 100 / (a.length + b.length));
      return Math.trunc(percentage)
    },

    upFile(file, item){
      //this.$store.dispatch('uploadFileAsPromise', file)
      if(file == null){ return}
      if(item !== undefined && "drop" in item && item.drop == "polass"){ this.$store.commit('Add2FileQueue', {file: file, project: this.$parent.$parent.currentProject, filetype: 'policy', policyassociation: this.currentObj.id}); return }
      if(item !== undefined && "drop" in item && item.drop == "procass"){ this.$store.commit('Add2FileQueue', {file: file, project: this.$parent.$parent.currentProject, filetype: 'policy', processassociation: this.currentObj.id}); return }
      this.$store.commit('Add2FileQueue', {file: file, project: this.$parent.currentProject, filetype: 'policy'})
      
    },

    getFileList() {
      if (this.unsubscribeFiles != null){console.log("Unsubbed Files"); this.unsubscribeFiles();}
      console.log("Initiated Start File List")
      const q = query(collection(db, "projects/" + this.$parent.$parent.currentProject + "/policyfiles"), orderBy("timedate"));
      this.unsubscribeFiles = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {

          if (change.type === "added") {
            let docdata = change.doc.data()
            docdata["id"] = change.doc.id
            docdata["dtime"] = change.doc.data().timedate.toDate().toLocaleString()
            docdata["datediff"] = ((Date.now() / 1000) - change.doc.data().timedate.seconds) / 86400
            //docdata["dtime"] = Date(change.doc.data().timedate.seconds * 1000).toLocaleString()
            this.fileList.push(docdata);
            //this.fileList[change.doc.id] = change.doc.data()

            //console.log("Added:", change.doc.data())
          }
          if (change.type === "modified") {
            //Change
          }
          if (change.type === "removed") {
            let indexOfChange = this.fileList.findIndex(i => i.id === change.doc.id);
            this.fileList.splice(indexOfChange, 1)
            //console.log(indexOfChange)
            //Removed
          }
        });

      });

    },
    async deleteFile(file) {
      this.$refs.confdlg.open({title: "File Deletion", message: "Are you sure you want to delete this file?" }).then(result => {
            if (result == false){ console.log("Did not delete.") }
            if (result == true){ this.$store.commit('deleteQueue', {file: file, project: this.$parent.$parent.currentProject, type: "policy"}) }
          });
    },
    async deleteMultiFiles() {
      if (this.filesSelected.length == 0){ return }
      this.$refs.confdlg.open({title: "File Deletion", message: "Are you sure you want to delete these files?" }).then(result => {
            if (result == false){ console.log("Did not delete.") }
            if (result == true){ 
              this.filesSelected.forEach(item => this.$store.commit('deleteQueue', {file: item, project: this.$parent.$parent.currentProject, type: "policy"}) ); 
              this.filesSelected = []
              }
          });
    },

    getEvidenceInfo(){
      ////this.evidenceList = Object.values(this.currentObj.evidence)
      //this.evidenceList = Object.keys(this.currentObj.evidence).map((key) => ({ key, score: this.currentObj.evidence[key].score, request: this.currentObj.evidence[key].request }));
      this.evidenceList = [] //clear array first

      for (var element of Object.keys(this.currentObj.elements)) { //loop through elements
        let elementobj = { key: element, score: this.currentObj.elements[element].score, request: this.currentObj.elements[element].text, type: "el" };
        this.evidenceList.push(elementobj)

          for (var evidence of Object.keys(this.currentObj.elements[element].evidence)) { //loop through evidence
          let evidenceobj = { key: evidence, score: this.currentObj.elements[element].evidence[evidence].score, request: this.currentObj.elements[element].evidence[evidence].request, type: "ev" }; 
          this.evidenceList.push(evidenceobj)
          }

      }

      this.tableLoading = false
      this.sideLoading = false

      console.log("currentobj element", this.currentObj.elements)
      console.log(this.evidenceList)

    },

    async addPolicyAssociation(file){
      await updateDoc(doc(db, "projects/" + this.$parent.$parent.currentProject + "/policyfiles", file.id), {
          policyassociation: arrayUnion(this.$parent.$parent.currentUID)
      });
      this.$store.commit('alertpop', { message: 'Added policy ' + file.name + ' to ' +  this.$parent.$parent.currentUID, type: "success" });
    },
    async addMultiPolicyAssociation(){
      if (this.filesSelected.length == 0){ return }
      const polbatch = writeBatch(db);

      this.filesSelected.forEach(item => {
      let ref = doc(db, "projects/" + this.$parent.$parent.currentProject + "/policyfiles/", item.id)
       polbatch.update(ref, { policyassociation: arrayUnion(this.$parent.$parent.currentUID) });
      } ); 

      try {
      //write batch
      await polbatch.commit();

      this.$store.commit('alertpop', { message: 'Files associated successfully.', type: "success" });
        } catch(e) {
          this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });
          console.error(e);
          }

      this.filesSelected = []          
    },
    async addProcessAssociation(file){
      await updateDoc(doc(db, "projects/" + this.$parent.$parent.currentProject + "/policyfiles", file.id), {
          processassociation: arrayUnion(this.$parent.currentUID)
      });
      this.$store.commit('alertpop', { message: 'Added process ' + file.name + ' to ' +  this.$parent.$parent.currentUID, type: "success" });
    },
    async addMultiProcessAssociation(){
      if (this.filesSelected.length == 0){ return }
      const probatch = writeBatch(db);

      this.filesSelected.forEach(item => {
      let ref = doc(db, "projects/" + this.$parent.$parent.currentProject + "/policyfiles/", item.id)
       probatch.update(ref, { processassociation: arrayUnion(this.$parent.$parent.currentUID) });
      } ); 

      try {
      //write batch
      await probatch.commit();

      this.$store.commit('alertpop', { message: 'Files associated successfully.', type: "success" });
        } catch(e) {
          this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });
          console.error(e);
          }

      this.filesSelected = []          
    },
    async remPolicyAssociation(file){
      await updateDoc(doc(db, "projects/" + this.$parent.$parent.currentProject + "/policyfiles", file.id), {
          policyassociation: arrayRemove(this.$parent.$parent.currentUID)
      });
      this.$store.commit('alertpop', { message: 'Removed policy ' + file.name + ' from ' +  this.$parent.$parent.currentUID, type: "success" });
    },
    async remProcessAssociation(file){
      await updateDoc(doc(db, "projects/" + this.$parent.$parent.currentProject + "/policyfiles", file.id), {
          processassociation: arrayRemove(this.$parent.$parent.currentUID)
      });
      this.$store.commit('alertpop', { message: 'Removed process ' + file.name + ' from ' +  this.$parent.$parent.currentUID, type: "success" });
    },

    pauseFile(){
      this.$store.state.uploadFile.task.pause()
    },
    resumeFile(){
      this.$store.state.uploadFile.task.resume()
    },
    stopFile(){
      this.$store.state.uploadFile.task.cancel()
    },
    polupdate_dlg: function(event) {
      this.$refs.confdlg.open({title: "Policy Update", message: "Another user has updated the policy language in this requirement. Would you like to refresh with the update? You will lose your work. Any further saved changes will overwrite the other users changes." }).then(result => {
        if (result == false){ console.log("Did not update.") }
        if (result == true){ console.log("Updated.") }
        //console.log("Result:", result);
      });
    },
    refreshPolicy(){
      this.$refs.confdlg.open({title: "Policy Refresh", message: "Are you sure you want to revert the policy language to the last saved state?" }).then(result => {
            if (result == false){ console.log("Did not refresh.") }
            //if (result == true){ this.$set(this.currentObj, 'poltxt', this.$store.state.docuMaster[this.$parent.$parent.currentProject][this.$parent.$parent.currentUID].poltxt) }
            if (result == true){ this.currentObj.poltxt = this.$store.state.docuMaster[this.$parent.$parent.currentProject][this.$parent.$parent.currentUID].poltxt; }
          });
    },
    refreshProcess(){
      this.$refs.confdlg.open({title: "Process Refresh", message: "Are you sure you want to revert the process language to the last saved state?" }).then(result => {
            if (result == false){ console.log("Did not refresh.") }
            //if (result == true){ this.$set(this.currentObj, 'proctxt', this.$store.state.docuMaster[this.$parent.$parent.currentProject][this.$parent.$parent.currentUID].proctxt) }
            if (result == true){ this.currentObj.proctxt = this.$store.state.docuMaster[this.$parent.$parent.currentProject][this.$parent.$parent.currentUID].proctxt }
          });
    },
    async saveRequirement(){
      this.saving = true; //Turn on the loading button
      this.currentObj.polscore = this.currentPolicyScore //set the policy score in current object
      this.currentObj.procscore = this.currentProcessScore //set the policy score in current object

      const batch = writeBatch(db);

      //setup policy file page number batch write
      Object.keys(this.associatedPolicyComputeObj).forEach((key) => {
       let ref = doc(db, "projects/" + this.$parent.$parent.currentProject + "/policyfiles/", key)
       batch.set(ref, this.associatedPolicyComputeObj[key], { merge: true });
        });

      //setup process file page number batch write
      Object.keys(this.associatedProcessComputeObj).forEach((key) => {
       let ref = doc(db, "projects/" + this.$parent.$parent.currentProject + "/policyfiles/", key)
       batch.set(ref, this.associatedProcessComputeObj[key], { merge: true });
        });

      //add requirement changes to batch write
      let rref = doc(db, "projects/" + this.$parent.$parent.currentProject + "/requirements/", this.$parent.$parent.currentUID)
      batch.set(rref, this.currentObj, { merge: true });
      console.log(rref)

      //write NA's to evidence
      if(this.currentObj.setna == true){
      Object.keys(this.currentObj.elements).forEach((key) => {
        Object.keys(this.currentObj.elements[key].evidence).forEach((evkey) => {

          let naref = doc(db, "projects/" + this.$parent.$parent.currentProject + "/evidence/", evkey)          
          batch.update(naref, { status: 3, na: true });

          let nareqref = doc(db, "projects/" + this.$parent.$parent.currentProject + "/requirements/", this.$parent.$parent.currentUID)
          let updateData = {};
          updateData[`elements.${key}.evidence.${evkey}.score`] = "NA";
          batch.update(nareqref, updateData);    
          
          
          
          console.log("NA updates for: ", evkey)

          });

          let updateData = {};
          updateData[`elements.${key}.score`] = "NA";
          let keyRef = doc(db, "projects/" + this.$parent.$parent.currentProject + "/requirements/", this.$parent.$parent.currentUID);
          batch.update(keyRef, updateData);

        });
      }

      try {
      //write batch
      await batch.commit();
      this.saving = false; //Turn off the loading button
      this.$store.commit('alertpop', { message: 'Saved successfully.', type: "success" });
      this.$store.dispatch('auditLog', { project: this.$parent.$parent.currentProject, uid: this.$parent.$parent.currentUID, action: "Saved Requirement" });
        } catch(e) {
          this.saving = false; //Turn off the loading button
          this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });
          console.error(e);
          }


    },
    calcImplScore(){
      //currentObj.implscore
          if (this.currentObj.imploverride == true ){ return }

          // let uidscoresObj = this.currentObj.evidence //this is the current UID evidence object

          // //calculate new score for this uid based on current object data
          // var addScore = 0
          // for (let key in uidscoresObj) {
          //  addScore += uidscoresObj[key].score //add together scores from all evidence in this UID
          // }
          // let calcScore = addScore / Object.keys(uidscoresObj).length
          // let finalscore = this.scoreAdjust(calcScore)
          // this.currentObj.implscore = finalscore

          let uidscoresObj = this.currentObj.elements //this is the current UID elements object

          var uidScore = 0
          for (let elkey in uidscoresObj) { //average element scores together for this UID
            uidScore += uidscoresObj[elkey].score //add together scores from all evidence in this UID
          }          
          let calcelScore = uidScore / Object.keys(uidscoresObj).length
          let uidFinalscore = this.scoreAdjust(calcelScore)
          this.currentObj.implscore = uidFinalscore

    },
    scoreAdjust(scorein){

      if (isNaN( scorein)){ return 0 }      
      if ( scorein >= 0 &&  scorein <= 10) { return 0 } //Very low
      if ( scorein >= 11 &&  scorein <= 32) { return 25 } //Low
      if ( scorein >= 33 &&  scorein <= 65) { return 50 } //Moderate
      if ( scorein >= 66 &&  scorein <= 89) { return 75 } //High
      if ( scorein >= 90 &&  scorein <= 100) { return 100 } //Very high

      return 0 

    },     
    openEvidence(evid){
      
      if (this.evidenceWin != null && this.evidenceWin.closed == false){
        //check if window is already open and EVID send as data message
        let msg = {evid: evid}
        this.evidenceWin.postMessage(msg,'*')
        this.evidenceWin.focus()

      } else {
        //open a new tab
        let routeData = this.$router.resolve({name: 'Evidence', query: {evid: evid, project: this.$parent.$parent.currentProject}});
        this.evidenceWin = window.open(routeData.href, 'evidencetab') 
      }
    },
    goForward(){
      this.$parent.$parent.moveSel('forward')
    },
    goBack(){
      this.$parent.$parent.moveSel('back')
    }
  },
  computed: {
    projectType (){
      //if (this.$store.state.projMasterObj[this.$parent.currentProject].type == "i1") { return "i1" }
      //return "error"
      const project = this.$store.state.projMasterObj[this.$parent.$parent.currentProject];

      // Use optional chaining to check if project and project.type exist
      return project?.type === "i1" ? "i1" : "error";
    },
    currentUID (){
      return this.$parent.$parent.currentUID
    },
    currentTopic(){
      if('topic' in this.currentObj){return this.$store.state.topicList[this.currentObj.topic].name}
      return null
      
    },
    currentPolicyScore(){
      let tierscore = this.currentObj.tierscore; //Tier 2 is actually tier 4 due to v-select functions
      let elementcount = 0;
      //this.currentObj.elements.forEach(function (item, index) { if( item.satisfied == true ){ elementcount++ } });
      for (var element of Object.keys(this.currentObj.elements)) {  
        //console.log(element, this.currentObj.elements[element].satisfied);   
        if( this.currentObj.elements[element].satisfied == true ){ elementcount++ }
        }

      let elementscore = (elementcount / Object.keys(this.currentObj.elements).length) * 100
      console.log("Policy element score: ", elementscore);

      if (tierscore == 0){ return 0 }
      if (elementscore == 0){ return 0 }      

      if (elementscore >= 0 && elementscore <= 10) { return 0 } //Very low
      if (elementscore >= 11 && elementscore <= 32) { return 25 } //Low
      if (elementscore >= 33 && elementscore <= 65) { if ( tierscore == 2) { return 50 } else { return 25 } } //Moderate
      if (elementscore >= 66 && elementscore <= 89) { if ( tierscore == 2) { return 75 } else { return 25 } } //High
      if (elementscore >= 90 && elementscore <= 100) { if ( tierscore == 2) { return 100 } else { return 25 }  } //Very high
      
      return "Uncalculated"
    },
    currentProcessScore(){
      let tierscore = this.currentObj.proctierscore; //Tier 2 is actually tier 4 due to v-select functions
      let elementcount = 0;
      //this.currentObj.elements.forEach(function (item, index) { if( item.procsatisfied == true ){ elementcount++ } });
      for (var element of Object.keys(this.currentObj.elements)) {  
        if( this.currentObj.elements[element].procsatisfied == true ){ elementcount++ }
        }

      let elementscore = (elementcount / Object.keys(this.currentObj.elements).length) * 100
      console.log("Process element score: ", elementscore);

      if (tierscore == 0){ return 0 }
      if (elementscore == 0){ return 0 }      

      if (elementscore >= 0 && elementscore <= 10) { return 0 } //Very low
      if (elementscore >= 11 && elementscore <= 32) { return 25 } //Low
      if (elementscore >= 33 && elementscore <= 65) { if ( tierscore == 2) { return 50 } else { return 25 } } //Moderate
      if (elementscore >= 66 && elementscore <= 89) { if ( tierscore == 2) { return 75 } else { return 25 } } //High
      if (elementscore >= 90 && elementscore <= 100) { if ( tierscore == 2) { return 100 } else { return 25 }  } //Very high
      
      return "Uncalculated"
    },
    illpolcolor(){
      if ( this.implShowCards.showpip == false ) { return "white" } else { return "green" }
    },
    scorecolor(){
      if ( this.implShowCards.showscoring == false ) { return "white" } else { return "green" }
    },
    gapscolor(){
      if ( this.implShowCards.showgaps == false ) { return "white" } else { return "green" }
    },
    tresultscolor(){
      if ( this.implShowCards.showtresults == false ) { return "white" } else { return "green" }
    },
    optionscolor(){
      if ( this.implShowCards.showoptions == false ) { return "white" } else { return "green" }
    },
    similarity(){
      if (this.currentObj.poltxt){ return this.LevenshteinDistance(this.currentObj.poltxt, this.currentObj.illpol); } else { return 0 }
    },
    testResults() {
      let totalScore = 0;
      let count = 0;
      let hundycount = 0;

      Object.keys(this.currentObj.elements).forEach(key => {
        if (this.currentObj.elements[key].score !== "NA" && !isNaN(this.currentObj.elements[key].score)) {
          totalScore += parseInt(this.currentObj.elements[key].score);
          count++;
          if (this.currentObj.elements[key].score == 100) {
            hundycount++;
          }
        }
      });

      let finalScore = count === 0 ? 0 : this.scoreAdjust(Math.round(totalScore / count));     


      return `FD examined evidence to confirm that ${hundycount} of the ${count} evaluative elements were satisfied. The overall score is ${finalScore}%.`;
    },
    implscoring(){
      let calcscore = "This score is based on an average of the " + Object.keys(this.currentObj.elements).length + " element scores which are presented in the 'Evidence Requests' table."
      let override = "This score is based on assessor discretion."
      if ( this.currentObj.imploverride == true ){ return override } else { return calcscore}
      //return "disabled"
    },
    allDocsCompute(){
      if (this.$store.getters.allDocGetter == null){ return []}
      return this.$store.getters.allDocGetter
    },
    associatedPolicyCompute(){
      if (this.$store.getters.allDocGetter == null){ return }
      let search = null      
      let evidence = []

      search = this.currentObj.id
    
      let masterray = this.$store.getters.allDocGetter
      
      masterray.forEach(item => {
        if('policyassociation' in item && item.policyassociation.includes(search)){ evidence.push(item) }
      })
      return evidence
      
    },   
    associatedPolicyComputeObj(){
      if (this.$store.getters.allDocGetter == null){ return }
      let search = null      
      let evidence = {}

      search = this.currentObj.id
    
      let masterray = this.$store.getters.allDocGetter
      
      masterray.forEach(item => {
        if('policyassociation' in item && item.policyassociation.includes(search)){ 
          if(!("policypage" in item)){ item["policypage"] = {}}
          if(!(this.currentObj.id in item.policypage)){ item.policypage[this.currentObj.id] = "" }
          evidence[item.id] = item 
          }
      })
      return evidence
      
    },     
    associatedProcessCompute(){
      if (this.$store.getters.allDocGetter == null){ return }
      let search = null      
      let evidence = []

      search = this.currentObj.id
    
      let masterray = this.$store.getters.allDocGetter
      
      masterray.forEach(item => {
        if('processassociation' in item && item.processassociation.includes(search)){ evidence.push(item)}
      })
      return evidence
      
    },  
    associatedProcessComputeObj(){
      if (this.$store.getters.allDocGetter == null){ return }
      let search = null      
      let evidence = {}

      search = this.currentObj.id
    
      let masterray = this.$store.getters.allDocGetter
      
      masterray.forEach(item => {
        if('processassociation' in item && item.processassociation.includes(search)){ 
          if(!("processpage" in item)){ item["processpage"] = {}}
          if(!(this.currentObj.id in item.processpage)){ item.processpage[this.currentObj.id] = "" }
          evidence[item.id] = item 
          }
      })
      return evidence
      
    },             

  },
  beforeMount() {
    this.columnDefs = [
        { headerName: 'UID', field: 'uid', sortable: true, filter: true, resizable: true },
        { headerName: 'Domain', field: 'dom', sortable: true, filter: true, resizable: true },
        { headerName: 'Requirement', field: 'req', sortable: true, filter: true, resizable: true, flex: 1  },
        { headerName: 'Pol Score', field: 'polscore', sortable: true, filter: true, resizable: true },
        { headerName: 'Proc Score', field: 'procscore', sortable: true, filter: true, resizable: true }
          ];
      },

  mounted() {
    //this.rowData = this.$store.state.docuMasterRay;
    console.log('this.$refs.drawer:', this.$refs.drawer);
    console.log('this.$refs.drawer.$el:', this.$refs.drawer.$el);

    //this.setBorderWidth();
    this.setEvents();
    this.navigation.width = this.$store.state.docuRMenuWidth;

    //console.log("User: ", this.$store.state.user.name);
    this.getFileList();
    console.log(this.$parent.$parent.currentUID)

    this.loadUserPrefs();

  },
  unmounted() {
    console.warn("rightnav unmounted")
    document.onkeyup = null
    document.onpaste = null

    if (this.unsubscribeChat) {
      this.unsubscribeChat();
    }
    this.unsubscribeFiles()    

    this.saveUserPrefs();
    
  },  

  watch: { //watch for current UID changes
    showhide(newVal) {
      this.localShowHide = newVal;
    },
    "currentObj.evidence"(){
      console.log("EVIDENCE CHANGE")
    },
    "$parent.$parent.currentUID"(nv) {
      console.log("parent uid change", this.$parent.$parent.currentUID);
      //console.log(this.$store.state.docuMaster[this.$parent.currentUID]);
      
      //Clear currentObj
      this.currentObj = null;

      //Move master into currentObj with stringify so that it has no reference
      //this.currentObj = JSON.parse(JSON.stringify(this.$store.state.docuMaster[this.$parent.currentProject][this.$parent.currentUID]));
      this.currentObj = JSON.parse(JSON.stringify(this.$store.state.docuMaster[this.$parent.$parent.currentProject][this.$parent.$parent.currentUID]));
      this.tableLoading = true
      this.sideLoading = true
      this.startChat();
      console.log("finished start chat")
      this.getEvidenceInfo();
    },
    "$store.state.docuMasterChange"(nv) {
      console.log("rnav watcher change", nv)
      if (nv.project == this.$parent.$parent.currentProject && nv.id == this.$store.state.currentUID){
        console.log("project match")

        //If policy text of doducment that changed is not the same as the current policy text.
        if ( nv.poltxt != this.currentObj.poltxt ){ 
          console.log("poltxt mismatch")
          this.$refs.confdlg.open({title: "Policy Update", message: "Another user has updated the policy language in this requirement. Would you like to refresh with the update? You will lose your work. Any further saved changes will overwrite the other users changes." }).then(result => {
            if (result == false){ console.log("Did not update.") }
            //if (result == true){ this.$set(this.currentObj, 'poltxt', nv.poltxt) }
            if (result == true){ this.currentObj.poltxt = nv.poltxt; }
          });
        }

        //If policy gaps of doducment that changed is not the same as the current policy text.
        if ( nv.polgaps != this.currentObj.polgaps ){ 
          console.log("polgaps mismatch")
          this.$refs.confdlg.open({title: "Gaps Update", message: "Another user has updated the policy gaps language in this requirement. Would you like to refresh with the update? You will lose your work. Any further saved changes will overwrite the other users changes." }).then(result => {
            if (result == false){ console.log("Did not update.") }
            //if (result == true){ this.$set(this.currentObj, 'polgaps', nv.polgaps) }
            if (result == true){ this.currentObj.polgaps = nv.polgaps; }
          });
        }

        if ( nv.testnarrative != this.currentObj.testnarrative ){ 
          console.log("testnarrative mismatch")
          this.$refs.confdlg.open({title: "Narrative Update", message: "Another user has updated the narrative language in this requirement. Would you like to refresh with the update? You will lose your work. Any further saved changes will overwrite the other users changes." }).then(result => {
            if (result == false){ console.log("Did not update.") }
            //if (result == true){ this.$set(this.currentObj, 'testnarrative', nv.testnarrative) }
            if (result == true){ this.currentObj.testnarrative = nv.testnarrative; }
          });
        }

        if ( nv.implgaps != this.currentObj.implgaps ){ 
          console.log("implgaps mismatch")
          this.$refs.confdlg.open({title: "Gaps Update", message: "Another user has updated the gaps language in this requirement. Would you like to refresh with the update? You will lose your work. Any further saved changes will overwrite the other users changes." }).then(result => {
            if (result == false){ console.log("Did not update.") }
            //if (result == true){ this.$set(this.currentObj, 'implgaps', nv.implgaps) }
            if (result == true){ this.currentObj.implgaps = nv.implgaps; }
          });
        }

        // //Refresh the evidence list when a change occurs
        // if ('evidence' in this.currentObj && nv.evidence != this.currentObj.evidence){   
        //   console.log("evidence change: ", nv.evidence, this.currentObj.evidence)
        //   this.currentObj.evidence = nv.evidence  
        //   this.getEvidenceInfo()
        //   this.calcImplScore() //Recalculate implemented score if evidence table changes
        // }
        //Refresh the element list when a change occurs
        if ('elements' in this.currentObj && nv.elements != this.currentObj.elements){   
          console.log("elements change: ", nv.elements, this.currentObj.elements)
          this.currentObj.elements = nv.elements  
          this.getEvidenceInfo()
          this.calcImplScore() //Recalculate implemented score if evidence table changes
        }        

      }
    }
  },

};
</script>

<style scoped>
/* Tooltip container */
.tooltip2 {
  position: absolute;
  background-color: #555;
  color: rgb(255, 255, 255);
  padding: 5px;
  border: 1px solid #3b3b3b;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  font-family: 'Roboto', sans-serif;
  font-size: smaller;

}


.tooltip {
  position:absolute;
  
  /* vertically center */
  top:50%;
  transform:translateY(55%);
  
  /* move to right */
  left:100%;
  margin-left:15px; /* and add a small left margin */
  
  /* basic styles */
  padding:10px;
  border-radius:10px;
  background:#000;
  color: #fff;
  text-align:center;
  white-space: nowrap;
  font-family: 'Roboto', sans-serif;
  font-size: smaller;
  z-index: 9999;
}

.tooltip:before {
  position:relative; /* making the .tooltip span a container for the tooltip text */
  border-bottom:1px dashed #000; /* little indicater to indicate it's hoverable */
}


.handle {
  cursor: move;
}

.v-textarea {
      font-size: 1.0em;
 }

.fixed-tabs-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0rem;
  z-index: 2;
}

.fade-enter-from { opacity: 0 }
.fade-enter-to { opacity: 1 }
.fade-enter-active { transition: all 2s ease }

.fade-leave-from { opacity: 1 }
.fade-leave-to { opacity: 0 }
.fade-leave-active { transition: all 2s ease }

.buttonFocus.v-btn--active::before {
 opacity: 0 !important;
}

.no-active {
  color: rgba(255, 255, 255, 0.7) !important
}

.selectwidth >>> .v-input__slot {
  width: min-content !important;
}

.selectwidth >>> .v-input__slot::before {
  border-style: none !important;
}

.v-data-table >>> .ev-row {
  background-color: #f0f0f09e  !important;
}
.resize-border {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px; /* Set your border width here */
  cursor: ew-resize;
  background-color: #475361;
  z-index: 9900;
}
.expansion-text {
  color: white;
  padding-bottom: 5px;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  opacity: 0.7;
  overflow: hidden;  
  padding-bottom: 5px;
  text-overflow: ellipsis;
  white-space: pre-wrap !important;
}

.expansion-pad {
  padding-bottom: 15px;
  display: inline-block;
}

.v-expansion-panel-title--active {
  min-height: 52px !important;
  height: 52px !important;
}

</style>
