<template>
    <div class="tooltip-wrapper">
        <slot />
        <!-- <div :class="classes"> -->
            <span :class="tooltipClasses" role="tooltip" :id="tooltipId" inert>{{ text }}</span>
        <!-- </div> -->
    </div>
</template>

<script>
export default {
    name: 'Tooltip',
    props: {
        text: {
            type: String,
            required: true
        },
        position: {
            type: String,
            default: 'bottom'
        },
        tooltipId: {
            type: String,
            default: 'tooltip__text'
        }        
    },
    data() {
        return {
            // Your component's data properties here
        };
    },
    methods: {
        // Your component's methods here
    },
    computed: {
        tooltipClasses() {
            return {
                'tooltip__text': true,
                [`tooltip--${this.position}`]: true
            };
        }
    },
    mounted() {
        // Your component's mounted lifecycle hook here
    },
    // Other component options (e.g., watch, props, etc.) can be added here
};
</script>

<style scoped>
/* Your component's styles here */

.tooltip-wrapper {
    position: relative;
    display: inline-block;
}
.tooltip__text {
    visibility: hidden;
    opacity: 0;

    color: #ffffff;
    text-align: center;
    padding: 5px;
    border-radius: 2px;
    min-width: 120px;
    background: #5e5d5d;
    white-space: nowrap;
    position: absolute;
    z-index: 9999;

    font-family: 'Roboto', sans-serif;
    font-size: smaller;
}

.tooltip-wrapper:hover .tooltip__text{
    visibility: visible;
    opacity: 1;
}



.tooltip--top {
    inset-block-end: 120%;
    inset-inline-start: 50%;
    margin-inline-start: -60px;
}

.tooltip--bottom {
    inset-block-start: 120%;
    inset-inline-start: 50%;
    margin-inline-start: -60px;
}

.tooltip--left {
    inset-block-end: 0%;
    inset-inline-end: 110%;
}

.tooltip--right {
    inset-block-end: 0%;
    inset-inline-start: 110%;
}

.tooltip--bottom-right {
    inset-block-start: 130%; 
    inset-inline-start: 110%;
}

.tooltip--bottom-left {
    inset-block-start: 130%; 
    inset-inline-end: 110%;
}

.tooltip__text::after {
    content: " ";
    position: absolute;
    border-width: 5px;
    border-style: solid;
    border-color: #5e5d5d transparent transparent transparent;
}



</style>