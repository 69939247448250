<template>

<v-container v-if="this.$store.getters.loaded == false">
    <v-overlay opacity="1" value="true">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
</v-container>

<v-container v-else class="py-5 main-view">

  <div class="whole-table">

          <div id="ev-list-bar" class="pa-1 pr-2">
            <v-icon icon="mdi-border-inside" color="info" class="pt-4 pl-4"></v-icon><span class="text-button pl-4 pt-1" style="color: white; font-size: large;">Vantage Organizations</span>
            <v-spacer></v-spacer>
            <v-btn variant="text" icon="mdi-playlist-plus" color="white" @click="neworg = true"></v-btn>
          </div>


        <ag-grid-vue style="width: 100%; height: calc(100% - 40px);"
            class="ag-theme-material"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :context="context"
            headerHeight="40"
            rowSelection="single"
            @grid-ready="onGridReady"
            @row-selected="onRowSelected">
        </ag-grid-vue>

      </div>

 


    <v-dialog v-model="settings" max-width="500">
        <v-toolbar color="#5f9a46" dark flat height=60>
            <v-toolbar-title class="text-subtitle-1">{{ organizationsObj[selectedOrg].name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon class="pr-5">mdi-cog</v-icon>            
        </v-toolbar>
      <v-form ref="saveOrgForm" @submit.prevent="SaveOrg()" lazy-validation>
      <v-card>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0 pt-10">
                <v-text-field v-model="organizationsObj[selectedOrg].domain" prepend-icon="mdi-domain" variant="solo" density="compact" label="Domain"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0">
                <v-text-field v-model="organizationsObj[selectedOrg].contact" :rules="emailRules" prepend-icon="mdi-account-box" variant="solo" density="compact" label="Contact*"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0">
                <v-text-field v-model="organizationsObj[selectedOrg].location" prepend-icon="mdi-map-marker" variant="solo" density="compact" label="Location"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="ma-0 pa-0">
                <v-switch v-model="organizationsObj[selectedOrg].enabled" label="Enabled" color="success" hide-details></v-switch>
              </v-col>
              <v-col cols="4" class="ma-0 pa-0">
                <v-switch v-model="organizationsObj[selectedOrg].mfa" label="MFA" color="success" hide-details></v-switch>
              </v-col>
              <v-col cols="4" class="ma-0 pa-0">
                <v-switch v-model="organizationsObj[selectedOrg].enforcemfa" label="Enforce MFA" color="success" hide-details></v-switch>
              </v-col>                            
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="settings = false">Close</v-btn>
          <v-btn color="blue darken-1" type="submit" text :loading="saving" :disabled="saving">Save</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="neworg" max-width="500">
        <v-toolbar color="#5f9a46" dark flat height=60>
            <v-toolbar-title class="text-subtitle-1">New Organization</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon class="pr-5">mdi-playlist-plus</v-icon>   
        </v-toolbar>
      <v-form ref="newOrgForm" @submit.prevent="SaveNewOrg()" lazy-validation>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0 pt-10">
                <v-text-field v-model="neworganization.name" :rules="nameRules" prepend-icon="mdi-label" variant="solo" density="compact" label="Name*"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0">
                <v-text-field v-model="neworganization.domain" prepend-icon="mdi-domain" variant="solo" density="compact" label="Domain"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0">
                <v-text-field v-model="neworganization.contact" :rules="emailRules" prepend-icon="mdi-account-box" variant="solo" density="compact" label="Contact*"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0">
                <v-text-field v-model="neworganization.location" prepend-icon="mdi-map-marker" variant="solo" density="compact" label="Location"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="ma-0 pa-0">
                <v-switch v-model="neworganization.mfa" label="MFA" color="success" hide-details></v-switch>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-switch v-model="neworganization.enforcemfa" label="Enforce MFA" color="success" hide-details></v-switch>
              </v-col>                            
            </v-row>            
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="neworg = false">Close</v-btn>
          <v-btn color="blue darken-1" type="submit" text :loading="saving" :disabled="saving">Save</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>


   </v-container>

</template>

<script>
import ListButtonOrg from '@/components/ListButtonOrg.vue'
import db from "@/main.js";

/*
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { AgGridVue } from "@ag-grid-community/vue";
*/

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue3';

import { collection, query, where, getDocs } from "firebase/firestore";
import { doc, setDoc, addDoc } from "firebase/firestore"; 

    export default {
        name: 'Orgs',
        data() {
            return {
                columnDefs: null,
                rowData: null,
                rowSelection: null,
                gridApi: null,
                columnApi: null,
                context: null,
                organizations: [],
                organizationsObj: { Initial: {name: "Initial"} },
                selectedOrg: "Initial",                
                neworganization: { enabled: true },
                orgsel: 0,
                menu: false,
                settings: false, //Opens settings modal
                neworg: false, //Opens new org modal
                saving: false, //Loader for the save button
                nameRules: [
                    v => !!v || 'Name is required',
                  ],
                emailRules: [
                    v => !!v || 'Valid e-mail is required',
                    v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
                  ],
                orgRules: [
                     v => !!v || 'Organization is required',
                  ],
                roleRules: [
                     v => !!v || 'Role is required',
                  ],
                //modules: [ClientSideRowModelModule, AllModules],
                
            }
        },
        components: {
            AgGridVue,
            
        },
        methods: {
            onGridReady(params) {
                this.gridApi = params.api;
                this.columnApi = params.columnApi;
            },
            onRowSelected(event) {
              if (event.node.selected){
              //this.$store.state.currentUID = event.node.data.uid;
              //console.log(event.node.data.uid);

              }
            },
            getSelectedRows() {
                const selectedNodes = this.gridApi.getSelectedNodes();
                const selectedData = selectedNodes.map( node => node.data );
                console.log(selectedData);
                const selectedDataStringPresentation = selectedData.map( node => `${node.make} ${node.model}`).join(', ');
                alert(`Selected nodes: ${selectedDataStringPresentation}`);
            },
            increment() {
                //this.$store.commit('increment')
                this.$store.state.docuMaster["0101.00a1Organizational.123"].dom = "changed";
                console.log(this.$store.state.docuMaster2)

            },
            async getOrgs() {
                const q = query(collection(db, "organizations"));
                const querySnapshot = await getDocs(q);
                this.organizations = []; //Clear the array before restructuring.
                querySnapshot.forEach((doc) => {
                    this.organizationsObj[doc.id] = doc.data();
                    let datapush = {id: doc.id, name: doc.data().name, enabled: doc.data().enabled, contact: doc.data().contact, location: doc.data().location, domain: doc.data().domain};
                    this.organizations.push(datapush);         
                console.log(this.orgobj);
                });
                console.log(this.organizations);
              this.rowData = this.organizations;

            },
            async SaveOrg() {
                if(!this.$refs.saveOrgForm.validate()){return} //Exit the function if form is not validated
                try {
                this.saving = true; //Turn on the loading button
                await setDoc(doc(db, "organizations", this.selectedOrg), this.organizationsObj[this.selectedOrg], { merge: true });
                this.saving = false; //Turn off the loading button
                this.settings = false; //Close the modal
                this.$store.commit('alertpop', { message: 'Saved successfully.', type: "success" });
                this.getOrgs(); //Redo the list of orgs
                  } catch(e) {
                    this.saving = false; //Turn off the loading button
                    this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });
                    console.error(e);
                    } 
            },
            async SaveNewOrg() {
                if(!this.$refs.newOrgForm.validate()){return} //Exit the function if form is not validated
                try {
                this.saving = true; //Turn on the loading button
                const docRef = await addDoc(collection(db, "organizations"), this.neworganization);
                console.log("Document written with ID: ", docRef.id);
                this.saving = false; //Turn off the loading button
                this.neworg = false; //Close the modal
                this.$store.commit('alertpop', { message: 'Saved successfully', type: "success" });
                this.getOrgs(); //Redo the list of orgs
                  } catch(e) {
                    this.saving = false; //Turn off the loading button
                    this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });
                    console.error(e);
                    } 
            },
        },

        beforeMount() {
          if (this.$store.getters.loaded == false){ this.$store.commit('beginLoading') }
            document.title = "Vantage - Organizations";
            this.context = {componentParent: this};
            this.columnDefs = [
                { headerName: 'Name', field: 'name', minWidth: 100, sortable: true, filter: true, resizable: true, flex: 1  },
                { headerName: 'Action', field: 'id', minWidth: 50, sortable: true, filter: true, resizable: true, cellRenderer: ListButtonOrg }
            ];
            //console.log(this.$store.state.count);
        },
        mounted() {
            this.getOrgs();
        },
    }
</script>

<style scoped>

#ev-list-bar {
    display: flex;
    flex-wrap: nowrap;
    background-color: #3d4856;
    white-space: nowrap;
    overflow: hidden;

}

.whole-table {
    justify-content: center;
    width: 100%;
    max-width: 1000px;
    height: 90%;
    
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.main-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
} 

</style>