<template>
<div align="center">

         <v-chip size="small" variant="flat" :color="statusColor()" :class="statustextColor()">{{ statusText() }}</v-chip>

</div>
</template>

<script>
import { defineComponent } from 'vue';
import tooltip from '@/components/Tooltip.vue'

export default defineComponent({
    methods: {
        statusColor(){
            if ( this.params.value == 0 ){ return "red"; }
            if ( this.params.value == 1 ){ return "primary"; }
            if ( this.params.value == 2 ){ return "green"; }
            if ( this.params.value == 3 ){ return "red"; }
            return "white"
        },
        statustextColor(){
            if ( this.params.value == 0 ){ return "text-white"; }
            if ( this.params.value == 1 ){ return "text-white"; }
            if ( this.params.value == 2 ){ return "text-white"; }
            if ( this.params.value == 3 ){ return "text-white"; }
            return "black--text"
        },
        statusText(){
            if ( this.params.value == 0 ){ return "NC"}
            if ( this.params.value == 1 ){ return "CC"}
            if ( this.params.value == 2 ){ return "AC"}
            if ( this.params.value == 3 ){ return "NA"}
            return "NC"
        },
        toolText(){
            if ( this.params.value == 0 ){ return "Not Complete"}
            if ( this.params.value == 1 ){ return "Client Complete"}
            if ( this.params.value == 2 ){ return "Asessor Complete"}
            if ( this.params.value == 3 ){ return "Not Applicable"}
            return "Not Complete"
        }
    },
    components: {
            tooltip
        },    
});
</script>