 <template>
   <div>
        <v-app-bar app clipped-left clipped-right flat dark color="#205392" v-if="this.$store.state.loggedIn && showBars == true">
            <v-app-bar-nav-icon @click="showNavbar = !showNavbar" v-if="this.$store.state.loggedIn && showBars == true"></v-app-bar-nav-icon>
            <v-toolbar-title class="text-uppercase">
              <v-img contain :src="require('@/assets/logo2.svg')" height="70" width="180"></v-img>
              
              
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <span class="project-title pr-10">{{ projName }}</span>
            <v-menu :close-on-content-click="true">
              <template v-slot:activator="{ props }">
                                       
                      <span class="white--text text-h5 pr-4" style="cursor: pointer;"><v-avatar variant="elevated" color="red" size="40" v-bind="props">{{ $store.state.user.name.charAt(0) }}</v-avatar></span>

              </template>
              <v-card width="400">
   
                <v-row class="ma-0 pt-5">
                  <v-col md="12" style="display: flex; justify-content: center">
                    <span class="white--text text-h4"><v-avatar color="red" size="70">{{ $store.state.user.name.charAt(0) }}</v-avatar></span>
                  </v-col>
                </v-row>
                <v-row class="ma-0">
                  <v-col class="pb-0" md="12" style="display: flex; justify-content: center">
                    <span class="text-button font-weight-bold">{{ $store.state.user.name }}</span><br>
                  </v-col>
                </v-row>
                <v-row class="ma-0">
                  <v-col class="pt-1" md="12" style="display: flex; justify-content: center">
                    <span class="text-body-2 text-lowercase">{{ $store.state.user.email }}</span>
                  </v-col>
                </v-row>          
                <v-row class="ma-0">
                  <v-col class="pt-2 pb-6" md="12" style="display: flex; justify-content: center">
                    <v-btn color="primary" text @click="goProfile()">User Settings</v-btn>
                  </v-col>
                </v-row>   
                <v-divider></v-divider>                
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text  @click="signOut()">Sign Out</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>            
            
          
        </v-app-bar>

        
        <v-navigation-drawer app clipped v-model="showNavbar" :mini-variant="mini" v-if="this.$store.state.loggedIn && showBars == true">
          <v-list nav dense>

            <v-list-subheader class="menu-title">Administration</v-list-subheader>

              <v-list-item link router to="/projects">
                <template v-slot:prepend>
                  <v-icon>mdi-view-list</v-icon>
                </template>
               <v-list-item-title>Projects</v-list-item-title>
              </v-list-item>

              <v-list-item link router to="/orgs">
                <template v-slot:prepend>
                  <v-icon>mdi-domain</v-icon>
                </template>
                <v-list-item-title>Organizations</v-list-item-title>
              </v-list-item>

              <v-list-item link router to="/users">
                <template v-slot:prepend>
                  <v-icon>mdi-account</v-icon>
                </template>
                <v-list-item-title>Users</v-list-item-title>
              </v-list-item>

            </v-list>
            <v-list nav dense  v-if="project">

            <v-list-subheader class="menu-title" v-if="project">Project</v-list-subheader>
            

              <v-list-item link router :to="dashpath">
                <template v-slot:prepend>
                  <v-icon>mdi-view-dashboard</v-icon>
                </template>
                <v-list-item-title>Dashboard</v-list-item-title>
              </v-list-item>

              <v-list-item link router :to="invpath">
                <template v-slot:prepend>
                  <v-icon>mdi-view-list</v-icon>
                </template>
                <v-list-item-title>Inventory</v-list-item-title>
              </v-list-item>

              <v-list-item link router :to="docupath">
                <template v-slot:prepend>
                  <v-icon>mdi-text-box-multiple</v-icon>
                </template>
                <v-list-item-title>Requirements</v-list-item-title>
              </v-list-item>

              <v-list-item link router :to="evpath">
                <template v-slot:prepend>
                  <v-icon>mdi-file-multiple</v-icon>
                </template>
                <v-list-item-title>Evidence</v-list-item-title>
              </v-list-item>

              <v-list-item link router :to="logpath">
                <template v-slot:prepend>
                  <v-icon>mdi-file-search</v-icon>
                </template>
                <v-list-item-title>Logs</v-list-item-title>
              </v-list-item>              

              <v-list-item link router :to="exportpath">
                <template v-slot:prepend>
                  <v-icon>mdi-export</v-icon>
                </template>
                <v-list-item-title>Export</v-list-item-title>
              </v-list-item>  




          </v-list>
        </v-navigation-drawer>

  </div>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";

export default {
  name: 'Navbar',

  data() {
    return {
        drawer: true,
        mini: false,
    }
  },
  methods: {
    signOut() {
      console.log("Signing Out: ", this.project)
      //this.$store.state.unsubscribeDocProject[this.project]()
      const auth = getAuth();
      signOut(auth).then(() => {
        // Sign-out successful.
        console.log("Signed out successfully.");
      }).catch((error) => {
        // An error happened.
        console.log(error);

      });
    },
    goProfile(){
      this.$router.push('profile');
    }
  },
  computed: {
    project (){
      return this.$route.query.project
    },
    showBars(){
      if ( this.$route.path == "/mfa" ){ return false }
      return true
    },
    projName() {
        if (this.$store.state.projMasterObj[this.$route.query.project]) {
            return this.$store.state.projMasterObj[this.$route.query.project].name;
        }
        return null; // or return an empty string "", or any other default value
    }, 
    showNavbar: {

    get () {
      return this.$store.state.showNavbar
    },
    set (value) {
      this.$store.commit('showhideNavbar', value)
      }
    },
    showTopbar: {

    get () {
      return this.$store.state.showTopbar
    },
    set (value) {
      this.$store.commit('showhideTopbar', value)
      }
    },
    docupath() {
      if(this.project){ return "/docuproject?project=" + this.project } else { return "/docuproject" }
    },
    dashpath() {
      if(this.project){ return "/projectdashboard?project=" + this.project } else { return "/projectdashboard" }
    },
    invpath() {
      if(this.project){ return "/inventory?project=" + this.project } else { return "/inventory" }
    },
    evpath() {
      if(this.project){ return "/evidence?project=" + this.project } else { return "/evidence" }
    },
    logpath() {
      if(this.project){ return "/logs?project=" + this.project } else { return "/logs" }
    },
    exportpath(){
      if(this.project){ return "/export?project=" + this.project } else { return "/export" }
    }
  },
  mounted(){
    console.log("Navbar: " + this.$route.query.project)
    console.log(this.project)
  }

};
</script>


<style scoped>
/* Add your CSS styles here */
.menu-title {

  letter-spacing: .05em;
  pointer-events: none;
  cursor: default;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: Inter, sans-serif;
  opacity: .5;
}

.project-title {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #d1d1f2;
  text-shadow: 2px 4px 3px rgba(0,0,0,0.2);
  text-transform: uppercase;
}
</style>
