<template>
<v-container v-if="this.$store.getters.loaded == false">
    <v-overlay opacity="1" value="true">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
</v-container>

<v-container v-else class="py-5" style="height: 100%;">

  <v-card class="pb-4">
    <v-toolbar color="#475361" dark flat height=60>
      <v-toolbar-title class="text-button">{{ $store.state.user.name }} User Profile</v-toolbar-title>
    </v-toolbar>

    <v-tabs vertical class="pt-3" v-model="tabs">
      <v-tab value="signin" style="justify-content: left;">
        <v-icon left>
          mdi-lock
        </v-icon>
        Sign-In
      </v-tab>      
      <v-tab value="prefs" style="justify-content: left;">
        <v-icon left>
          mdi-account
        </v-icon>
        Preferences
      </v-tab>
    </v-tabs>

    <v-window v-model="tabs">
      <v-window-item value="signin">
        <v-card flat>
          <v-card-text class="ml-6">
            <v-expansion-panels accordion style="maxWidth: 95%;" v-model="signinPanels" multiple>
              <v-expansion-panel class="mb-2">
                <v-expansion-panel-title class="pa-2 pl-6"><span class="text-button primary--text">Password Reset</span></v-expansion-panel-title>
                <v-expansion-panel-text>
                  <v-row> 
                    <v-col md="6">                 
                      <span>Send password reset email to your registered email address.</span>
                    </v-col>
                    <v-col md="6">                        
                      <v-btn small color="blue darken-1" @click="sendPasswordReset()" :loading="saving" :disabled="saving"><span class="white--text">Send</span></v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-text>
              </v-expansion-panel>

              <v-expansion-panel class="mb-2" v-if="$store.getters.allowMfa == true">
                <v-expansion-panel-title class="pa-2 pl-6"><span class="text-button primary--text">Multi-Factor Authentication</span></v-expansion-panel-title>
                <v-expansion-panel-text>
                  <v-row>       
                    <v-col md="12">
                      <span>Enroll in multi-factor authentication using SMS. Input your cell phone number and press enroll to complete your verification. You must have logged in recently to enroll, for security purposes.</span>
                    </v-col>
                  </v-row>

                  <v-row v-if="mfastate == false">
                    <v-col md="6">
                      <v-text-field v-model="phoneNum" label="Phone Number" placeholder="999 123 4567" class="pa-0"></v-text-field>
                    </v-col>
                    <v-col md="6" class="pt-4">
                      <v-btn small color="blue darken-1" @click="enroll()" :loading="saving" :disabled="saving"><span class="white--text">Enroll</span></v-btn>
                    </v-col>              
                  </v-row>

                  <v-row v-if="mfastate == true">
                    <v-col md="4">
                      <span class="red--text">You are currently enrolled in multi-factor authentication.</span>
                    </v-col>
                    <v-col md="4" class="pt-4">
                      <v-btn v-if="$store.getters.enforceMfa == false" small color="blue darken-1" @click="unEnroll()" :loading="saving" :disabled="saving"><span class="white--text">Un-Enroll</span></v-btn>
                    </v-col>              
                  </v-row>                 
                </v-expansion-panel-text>
              </v-expansion-panel>              

              <v-expansion-panel>
                <v-expansion-panel-title class="pa-2 pl-6"><span class="text-button primary--text">Authentication Providers</span></v-expansion-panel-title>
                <v-expansion-panel-text>
                  <v-row>
                    <v-col md="12">
                      <span>You are currently enrolled in the following authentication providers. You may unlink a specific provider if more than one is in use.</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="8">
                      <v-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                Provider
                              </th>
                              <th class="text-left">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in $store.state.user.obj.providerData" :key="item.name">
                              <td>
                                <v-icon v-if="item.providerId == 'microsoft.com'" class="pr-2">mdi-microsoft</v-icon>
                                <v-icon v-if="item.providerId == 'google.com'" class="pr-2">mdi-google</v-icon>
                                <v-icon v-if="item.providerId == 'password'" class="pr-2">mdi-web</v-icon>
                                {{ item.providerId }}</td>
                              <td>
                                <v-tooltip bottom v-if="$store.state.user.obj.providerData.length > 1">
                                    <template v-slot:activator="{ props }">                                                        
                                        <v-icon small v-bind="props" @click="$store.commit('unlinkProvider', item.providerId);">mdi-link-variant-off</v-icon>
                                    </template>
                                    <span>Unlink this authentication provider</span>
                                </v-tooltip>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-table>
                    </v-col>              
                  </v-row>                  
                </v-expansion-panel-text>
              </v-expansion-panel>  

            </v-expansion-panels>            
  
          </v-card-text>
        </v-card>
      </v-window-item>
      <v-window-item value="prefs">
        <v-card flat>
          <v-card-text class="ml-6">
          
          </v-card-text>
        </v-card>
      </v-window-item>
    </v-window>
  </v-card>

    <v-dialog v-model="twofamodal" max-width="500">
        <v-toolbar color="primary" dark flat height=60>
            <v-toolbar-title class="text-subtitle-1">SMS Code</v-toolbar-title>
        </v-toolbar>
      <v-form ref="twofaForm" lazy-validation @submit.prevent="submit2fa()">
        <v-card>
            <v-card-text>
            An SMS vertification code was sent to your mobile phone. Please enter the code below to continue.
            <v-text-field solo v-model="verificationCode" label="Code" class="pt-4"></v-text-field>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cleanUp(); twofamodal = false">Close</v-btn>
                <v-btn color="blue darken-1" text type="submit">Submit</v-btn>
            </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  
<div id="recaptcha-container"></div>

</v-container>
</template>

<script>

import { getAuth, sendPasswordResetEmail , PhoneMultiFactorGenerator, PhoneAuthProvider, RecaptchaVerifier, multiFactor } from "firebase/auth";
import db from "@/main.js";
import { doc, setDoc, addDoc } from "firebase/firestore"; 

    export default {
        name: 'Users',
        data() {
            return {
                columnDefs: null,
                twofamodal: false,
                verificationCode: null,
                verificationId: null,
                phoneNum: null,
                recaptchaVerifier: null,
                reComp: false,
                saving: false,
                signinPanels: [0,1,2],
                tabs: null
                

            }
        },
        components: {

            
        },
        methods: {
            test(){

            },
            enroll(){
              this.saving = true
              if (this.phoneNum == null){ return }

              if (this.phoneNum.match(/\d/g).length !== 10){ 
                this.$store.commit('alertpop', { message: 'Please input a properly formatted phone number.', type: "warning" });
                this.saving = false
                return 
                }

              console.log("enroll", this.phoneNum.replace(/\D/g,''))

              const auth = getAuth()
              const user = auth.currentUser
              const mfaUser = multiFactor(user); 
              var phoneNumber = "+1" + this.phoneNum.replace(/\D/g,'')

              this.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container',  {
                  'size': 'invisible',
                  'callback': function(response) {
                  // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
                  //onSolvedRecaptcha();
                  }
                  }, auth);
              console.log(user)
              
              mfaUser.getSession().then((multiFactorSession) =>  {
                // Specify the phone number and pass the MFA session.
                var phoneInfoOptions = {
                  phoneNumber: phoneNumber,
                  session: multiFactorSession
                };
                var phoneAuthProvider = new PhoneAuthProvider(auth);
                // Send SMS verification code.
                return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier)
                    .then((verificationId) => {
                        this.verificationId = verificationId
                        this.twofamodal = true
                    })
                    .catch((error) =>  {
                      if (error.code == "auth/requires-recent-login"){ 
                        this.$store.commit('alertpop', { message: 'Enrolling requires a recent login. Please log out and back in to try again.', type: "warning" });
                        this.cleanUp()
                        }
                    })
              })



            },
            submit2fa() {
                const auth = getAuth()
                const user = auth.currentUser
                const mfaUser = multiFactor(user);
                var phoneNumber = "+1" + this.phoneNum.replace(/\D/g,'')

                console.log("then veriID")
                var cred = PhoneAuthProvider.credential(this.verificationId, this.verificationCode);
                var multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
                // Complete sign-in.
                //return this.resolver.resolveSignIn(multiFactorAssertion)
                return mfaUser.enroll(multiFactorAssertion, phoneNumber)                
                .then((userCredential) =>  {
                    // User successfully signed in with the second factor phone number.
                    console.log("Enrolled: ", userCredential);
                    this.setEnrolled()
                    this.twofamodal = false
                    this.cleanUp()
                    })
                .catch((error) =>  {
                    console.log(error)
                    if (error.code == "auth/invalid-verification-code"){
                        this.$store.commit('alertpop', { message: 'Invalid verification code, please try again.', type: "warning" });             
                    }
                })
            },            
            cleanUp(){
                this.verificationCode = null
                this.verificationId = null
                this.phoneNum = null
                this.recaptchaVerifier.clear()
                this.saving = false
            },
            async setEnrolled(){
              this.$store.state.user.enrolledmfa = true
              await setDoc(doc(db, "users", this.$store.state.user.id), { enrolledmfa: true }, { merge: true });
              this.reComp = !this.reComp          
            },

            async setUnenrolled(){
              this.$store.state.user.enrolledmfa = false
              await setDoc(doc(db, "users", this.$store.state.user.id), { enrolledmfa: false }, { merge: true });              
              this.reComp = !this.reComp
            },            

            unEnroll(){
                const auth = getAuth()
                const user = auth.currentUser
                const mfaUser = multiFactor(user);              
                var options = mfaUser.enrolledFactors;
                // Ask user to select from the enrolled options.
                return mfaUser.unenroll(options[0])
                  .then(() => {
                    // User successfully unenrolled selected factor.
                    this.setUnenrolled()  
                    this.$store.commit('alertpop', { message: 'You have successfully been un-enrolled.', type: "success" });
                  })
                 .catch((error) =>  {
                    console.log(error)
                      if (error.code == "auth/requires-recent-login"){ 
                        this.$store.commit('alertpop', { message: 'Un-Enrolling requires a recent login. Please log out and back in to try again.', type: "warning" });
                        return
                        }                    
                    this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });             

                })                  
              },
            sendPasswordReset(){
              this.saving = true
              const auth = getAuth();
              sendPasswordResetEmail(auth, this.$store.state.user.email)
                .then(() => {
                  this.$store.commit('alertpop', { message: 'Password reset e-mail has been sent.', type: "success" });
                  this.saving = false
                })
                .catch((error) => {
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });
                  console.log(errorMessage);                  
                  this.saving = false
                });

            }                

        },
        computed: {          
          mfastate(){
            this.reComp
            if('enrolledmfa' in this.$store.state.user){ return this.$store.state.user.enrolledmfa}
            return false
          }
        },      

        beforeMount() {
          if (this.$store.getters.loaded == false){ this.$store.commit('beginLoading') }
            document.title = "Vantage - User Profile";
        },

        mounted() {

        },

    }
</script>

<style>



</style>