<template>
<v-container v-if="this.$store.getters.loaded == false">
    <v-overlay opacity="1" value="true">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
</v-container>

<v-container v-else class="py-5 pa-0 main-view">

  <div class="whole-table">

          <div id="ev-list-bar" class="pa-1 pr-2">
            <v-icon icon="mdi-border-inside" color="info" class="pt-4 pl-4"></v-icon><span class="text-button pl-4 pt-1" style="color: white; font-size: large;">Vantage Users</span>
            <v-spacer></v-spacer>
            <v-btn variant="text" color="white" icon="mdi-playlist-plus" @click="newuser = true"></v-btn>          
          </div>

        <ag-grid-vue style="width: 100%; height: calc(100% - 40px);"
            class="ag-theme-material"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :context="context"
            headerHeight="40"
            rowSelection="single"
            @grid-ready="onGridReady"
            @row-selected="onRowSelected">
        </ag-grid-vue>
    
    </div>

    <v-dialog v-model="settings" max-width="500">
        <v-toolbar color="#5f9a46" dark flat height=60>
            <v-toolbar-title class="text-subtitle-1">{{ userCollectionObj[selectedUser].name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon class="pr-5">mdi-cog</v-icon>            
        </v-toolbar>
      <v-form ref="editUserForm" @submit.prevent="editUser()" lazy-validation>
      <v-card>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0 pt-5">
                <v-text-field v-model="userCollectionObj[selectedUser].name" :rules="nameRules" prepend-icon="mdi-account-box" variant="solo" density="compact" label="Name"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0">
                <v-text-field v-model="userCollectionObj[selectedUser].title" prepend-icon="mdi-badge-account-horizontal-outline" variant="solo" density="compact" label="Title"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0">
                <v-select v-model="userCollectionObj[selectedUser].role" :rules="roleRules" :items="this.$store.state.roleMasterRay" item-title="name"  item-value="id" prepend-icon="mdi-account-box-multiple" label="Role*" variant="solo" density="compact"></v-select>
              </v-col>
            </v-row>
             <v-row>
              <v-col cols="12" class="ma-0 pa-0">
                <v-text-field v-model="userCollectionObj[selectedUser].phone" prepend-icon="mdi-phone" variant="solo" density="compact" label="Phone"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pb-5">
              <v-col cols="6" class="ma-0 pa-0">
                <v-switch v-model="userCollectionObj[selectedUser].enabled" label="Enabled" color="success" hide-details class="ma-0 pa-0"></v-switch>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0" align="right">
                <tooltip position="left" text="Send password reset email">    

                        <v-icon color="primary" @click="sendPasswordReset()">
                        mdi-lock-plus
                        </v-icon>

                </tooltip>
              </v-col>
            </v-row>               

          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="settings = false">Close</v-btn>
          <v-btn color="blue darken-1" type="submit" text :loading="saving" :disabled="saving">Save</v-btn>     
        </v-card-actions>
      </v-card>
    </v-form>
    </v-dialog>

    <v-dialog v-model="newuser" max-width="500">
        <v-toolbar color="#5f9a46" dark flat height=60>
            <v-toolbar-title class="text-subtitle-1">New User</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon class="pr-5">mdi-playlist-plus</v-icon>            
        </v-toolbar>
      <v-form ref="newUserForm" @submit.prevent="createUser()" lazy-validation>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0 pt-5">
                <v-select v-model="newUserObj.organization" :rules="orgRules" return-object :items="this.$store.state.orgMasterRay" item-title="name" prepend-icon="mdi-domain" label="Organization*" variant="solo" density="compact"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0">
                <v-text-field v-model="newUserObj.name" :rules="nameRules" prepend-icon="mdi-account-box" variant="solo" density="compact" label="Name*"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0">
                <v-text-field v-model="newUserObj.title" prepend-icon="mdi-badge-account-horizontal-outline" variant="solo" density="compact" label="Title"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0">
                <v-select v-model="newUserObj.role" :rules="roleRules" return-object :items="this.$store.state.roleMasterRay" item-title="name" prepend-icon="mdi-account-box-multiple" label="Role*" variant="solo" density="compact"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0">
                <v-text-field v-model="newUserObj.email" :rules="emailRules" prepend-icon="mdi-email" variant="solo" density="compact" label="Email*"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0">
                <v-text-field v-model="newUserObj.phone" prepend-icon="mdi-phone" variant="solo" density="compact" label="Phone"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="newuser = false">Close</v-btn>
          <v-btn color="blue darken-1" type="submit" text :loading="saving" :disabled="saving">Save</v-btn>        
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>

    </v-container>
</template>

<script>
import tooltip from '@/components/Tooltip.vue'
import ListButtonUser from '@/components/ListButtonUser.vue'
import ListRole from '@/components/ListRole.vue'
import ListEnabled from '@/components/ListEnabled.vue'
import db from "@/main.js";

/*
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { AgGridVue } from "@ag-grid-community/vue";
*/

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue3';


import { collection, query, where, getDocs } from "firebase/firestore";
import { doc, setDoc, addDoc } from "firebase/firestore"; 
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

    export default {
        name: 'Users',
        data() {
            return {
                columnDefs: null,
                rowData: null,
                rowSelection: null,
                gridApi: null,
                columnApi: null,
                tab: null,
                userCollection: [],
                userCollectionObj: { Initial: {name: "Initial"} },
                selectedUser: "Initial",
                context: null,
                newuser: false,
                newUserObj: {},
                saving: false,
                settings: false,
                nameRules: [
                    v => !!v || 'Name is required',
                  ],
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
                  ],
                orgRules: [
                     v => !!v || 'Organization is required',
                  ],
                roleRules: [
                     v => !!v || 'Role is required',
                  ],
                //modules: [ClientSideRowModelModule, AllModules],
            }
        },
        components: {
            AgGridVue,
            tooltip
        },
        methods: {
            test(){
              const functions = getFunctions();
              const moveTenant = httpsCallable(functions, 'moveTenant'); //Call the cloud function.
              moveTenant()
            },
            onGridReady(params) {
                this.gridApi = params.api;
                this.columnApi = params.columnApi;
            },
            onRowSelected(event) {
              if (event.node.selected){
              //this.$store.state.currentUID = event.node.data.uid;
              //console.log(event.node.data.uid);

              }
            },
            getSelectedRows() {
                const selectedNodes = this.gridApi.getSelectedNodes();
                const selectedData = selectedNodes.map( node => node.data );
                console.log(selectedData);
                const selectedDataStringPresentation = selectedData.map( node => `${node.make} ${node.model}`).join(', ');
                alert(`Selected nodes: ${selectedDataStringPresentation}`);
            },
            async getUsers() {
              const q = query(collection(db, "users"));
              const querySnapshot = await getDocs(q);
              this.userCollection = []; //Clear the array before restructuring.
              querySnapshot.forEach((doc) => {
                  this.userCollectionObj[doc.id] = doc.data(); //Populate user collection object then array below for the list
                  //console.log(this.$store.state.orgMaster);
                  let orgName = this.$store.state.orgMaster[doc.data().organization].name //Get org name from ID from store.
                  //let orgName = "test";
                  //Data below is the array for the ag-grid
                  let userData = {id: doc.id, name: doc.data().name, organization: orgName, role: doc.data().role, enabled: doc.data().enabled, email: doc.data().email};                    
                  this.userCollection.push(userData);                            
              });
              this.userCollection.sort((a, b) => a.name.localeCompare(b.name));
              this.rowData = this.userCollection;              

            },
            async createUser(){
              if(!this.$refs.newUserForm.validate()){return} //Exit the function if form is not validated
              const functions = getFunctions();
              const createUser = httpsCallable(functions, 'createUser'); //Call the cloud function.
              this.saving = true;
              createUser( this.newUserObj )
                .then((result) => {
                  // Read result of the Cloud Function.
                  //Success
                  if (result.data.uid) { 
                    console.log(result.data.uid) 
                    this.saving = false;
                    this.getUsers();
                    this.$store.commit('alertpop', { message: 'The user account has been created successfully.', type: "success" });
                    this.newuser = false;                    
                    }
                  //Error
                  if (result.data.errorInfo) { 
                    console.log(result.data.errorInfo.message) 
                    this.saving = false;
                    this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });
                    }

                  this.newUserObj = {} //Clear new user form
                  });

            },

            async editUser() {                
                if(!this.$refs.editUserForm.validate()){return} //Exit the function if form is not validated
                try {
                this.saving = true; //Turn on the loading button
                await setDoc(doc(db, "users", this.selectedUser), this.userCollectionObj[this.selectedUser], { merge: true });
                this.saving = false; //Turn off the loading button
                this.settings = false; //Close the modal
                this.$store.commit('alertpop', { message: 'Saved successfully.', type: "success" });
                this.getUsers(); //Redo the list of orgs
                  } catch(e) {
                    this.saving = false; //Turn off the loading button
                    this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });
                    console.error(e);
                    }
                /*Change password                
                if("password" in this.userCollectionObj[this.selectedUser] && this.userCollectionObj[this.selectedUser].password.length > 0){
                  console.log("pass change")
                  }
                */

            },
            sendPasswordReset(){
              const auth = getAuth();
              sendPasswordResetEmail(auth, this.userCollectionObj[this.selectedUser].email)
                .then(() => {
                  this.$store.commit('alertpop', { message: 'Password reset e-mail has been sent.', type: "success" });
                })
                .catch((error) => {
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });
                  console.log(errorMessage);                  
                });

            }          

        },

        beforeMount() {
          if (this.$store.getters.loaded == false){ this.$store.commit('beginLoading') }
            document.title = "Vantage - Users";
            this.context = {componentParent: this};
            this.columnDefs = [
                { headerName: 'Name', field: 'name', maxWidth: 300, sortable: true, filter: true, resizable: true },
                { headerName: 'E-Mail', field: 'email', mmaxWidth: 200, sortable: true, filter: true, resizable: true },
                { headerName: 'Organization', field: 'organization', maxWidth: 150,  sortable: true, filter: true, resizable: true, flex: 1 },
                { headerName: 'Role', field: 'role', maxWidth: 100, sortable: true, filter: true, resizable: true, cellRenderer: ListRole  },
                { headerName: 'Enabled', field: 'enabled', width: 130, sortable: true, filter: true, resizable: true, cellRenderer: ListEnabled},
                { headerName: 'Action', field: 'id', width: 130, sortable: true, filter: true, resizable: true, cellRenderer: ListButtonUser }
            ];
        },

        mounted() {
          this.getUsers();
        },

    }
</script>

<style scoped>
/*
  @import "../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
  @import "../../node_modules/ag-grid-community/dist/styles/ag-theme-material.css";
*/
#ev-list-bar {
    display: flex;
    flex-wrap: nowrap;
    background-color: #3d4856;
    white-space: nowrap;
    overflow: hidden;

}

.whole-table {
    justify-content: center;
    width: 100%;
    max-width: 1000px;
    height: 90%;
    
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.main-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
} 

</style>