<template>
<v-container v-if="this.$store.getters.loaded == false">
    <v-overlay opacity="1" value="true">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
</v-container>

<v-container v-else class="py-5" style="height: 100%;">


</v-container>
</template>

<script>

import { getAuth, sendPasswordResetEmail , PhoneMultiFactorGenerator, PhoneAuthProvider, RecaptchaVerifier, multiFactor } from "firebase/auth";
import db from "@/main.js";
import { doc, setDoc, addDoc } from "firebase/firestore"; 

    export default {
        name: 'Users',
        data() {
            return {
                columnDefs: null,
               

            }
        },
        components: {

            
        },
        methods: {

            begin(){
              if (this.$store.state.user.org != null && this.$store.state.user.org in this.$store.state.orgMaster){ 
                if ( this.$store.state.orgMaster[this.$store.state.user.org].enforcemfa == true && multiFactor(this.$store.state.user.obj).enrolledFactors.length == 0){ 
                  console.warn("begin enforce MFA")
                  this.$router.push('/mfa');
                  return
                  }
              }
              this.$router.push('/projects');
            }
          

        },
        computed: {          
          testcomp(){
            return false
          }
        },      

        beforeMount() {
          if (this.$store.getters.loaded == false){ this.$store.commit('beginLoading') }
            document.title = "Vantage - User Profile";
        },

        mounted() {

        },
        watch: { 

          "$store.getters.loaded"(nv) {
            console.log("auth loader change", nv)
            if (nv == true){
              this.begin()
             }

          },

        }


    }
</script>

<style>



</style>