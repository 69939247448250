<template>
<div>

<v-btn variant="text" icon="mdi-cog" color="red lighten-2" @click="settingsBtnClicked()">
    
</v-btn>

<v-btn variant="text" icon="mdi-open-in-new" color="blue lighten-2" @click="openBtnClicked()">
    
</v-btn>

</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    methods: {
        settingsBtnClicked() {
            this.params.context.componentParent.selectedOrg = this.params.value
            this.params.context.componentParent.settings = true;
            //alert(this.params.context.componentParent.selectedUser);

        },
        openBtnClicked() {
            //console.log(this.params.value)
            this.params.context.componentParent.openProject(this.params.value, "pop")

            //alert(this.params.context.componentParent.selectedUser);

        }    
    }
});
</script>