<template>
<v-container v-if="this.$store.getters.loaded == false">
    <v-overlay opacity="1" value="true">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
</v-container>


<v-container v-else class="py-5 main-view">

<div class="whole-table">

  <div id="ev-list-bar" class="pa-1 pr-2">
    <v-icon icon="mdi-border-inside" color="info" class="pt-4 pl-4" @click="test"></v-icon><span class="text-button pl-4 pt-1" style="color: white; font-size: large;">Vantage Projects</span>
      <v-spacer></v-spacer>
      <v-btn variant="text" icon="mdi-playlist-plus" color="white" @click="newproj = true"></v-btn>
  </div>


        <ag-grid-vue style="width: 100%; height: calc(100% - 40px);"
            class="ag-theme-material"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :context="context"
            headerHeight="40"
            rowSelection="single"
            @grid-ready="onGridReady"
            @row-selected="onRowSelected">
        </ag-grid-vue>

  </div>


  <v-dialog v-model="newproj" max-width="500">
        <v-toolbar color="#5f9a46" dark flat height=60>
            <v-toolbar-title class="text-subtitle-1">New Project</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon class="pr-5">mdi-playlist-plus</v-icon>   
        </v-toolbar>
      <v-form ref="newProjForm" @submit.prevent="SaveNewProj()" lazy-validation>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0 pt-10">
                <v-text-field v-model="newproject.name" :rules="nameRules" prepend-icon="mdi-label" variant="solo" density="compact" label="Name*"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0">
                <v-select v-model="newproject.org" :rules="orgRules" :items="organizations" item-title="name" return-object prepend-icon="mdi-domain" label="Organization*" variant="solo" density="compact"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0">
                <v-select v-model="newproject.type" :rules="typeRules" :items="projectTypes"  prepend-icon="mdi-list-box" label="Type*" variant="solo" density="compact"></v-select>
              </v-col>
            </v-row>                        
         
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="newproj = false">Close</v-btn>
          <v-btn color="blue darken-1" type="submit" text :loading="saving" :disabled="saving">Save</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>


 </v-container>

</template>

<script>
import ListButtonDocuProj from '@/components/ListButtonDocuProj.vue'
import ListDocuProjLink from '@/components/ListDocuProjLink.vue'
import db from "@/main.js";

/*
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { AgGridVue } from "@ag-grid-community/vue";
*/
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue3';



import { collection, query, where, getDocs } from "firebase/firestore";
import { doc, setDoc, addDoc } from "firebase/firestore"; 
import { getFunctions, httpsCallable } from "firebase/functions";

    export default {
        name: 'Orgs',
        data() {
            return {
                columnDefs: null,
                rowData: null,
                rowSelection: null,
                gridApi: null,
                columnApi: null,
                context: null,
                projects: [],
                projectsObj: { Initial: {name: "Initial"} },
                selectedProj: "Initial",                
                newProject: { enabled: true },
                projsel: 0,
                menu: false,
                settings: false, //Opens settings modal
                neworg: false, //Opens new org modal
                saving: false, //Loader for the save button
                nameRules: [
                    v => !!v || 'Name is required',
                  ],
                emailRules: [
                    v => !!v || 'Valid e-mail is required',
                    v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
                  ],
                orgRules: [
                     v => !!v || 'Organization is required',
                  ],
                typeRules: [
                     v => !!v || 'Type is required',
                  ],
                //modules: [ClientSideRowModelModule, AllModules],
                projectsLoaded: false,
                newproject: { enabled: true },
                newproj: false, //Opens new proj modal
                organizations: [],
                organizationsObj: { Initial: {name: "Initial"} },
                projectTypes: ['i1'],
            }
        },
        components: {
            AgGridVue,
            
        },
        methods: {
            test() {
              //this.$store.commit('alertpop', { message: 'The project has been created successfully.', type: "success" });
              //this.rowData = this.$store.state.projMasterRay;
              this.loadProjects()
            },
            onGridReady(params) {
                this.gridApi = params.api;
                this.columnApi = params.columnApi;
            },
            onRowSelected(event) {
              if (event.node.selected){
              //this.$store.state.currentUID = event.node.data.uid;
              //console.log(event.node.data.uid);

              }
            },
            getSelectedRows() {
                const selectedNodes = this.gridApi.getSelectedNodes();
                const selectedData = selectedNodes.map( node => node.data );
                console.log(selectedData);
                const selectedDataStringPresentation = selectedData.map( node => `${node.make} ${node.model}`).join(', ');
                alert(`Selected nodes: ${selectedDataStringPresentation}`);
            },
            openProject(projid, type){
              let routeData = this.$router.resolve({name: 'ProjectDashboard', query: {project: projid}});
              if (type == "pop") { window.open(routeData.href, '_blank') } else { this.$router.push({ name: 'ProjectDashboard', query: { project: projid } }) }
            },

            async getOrgs() {
                const q = query(collection(db, "organizations"));
                const querySnapshot = await getDocs(q);
                this.organizations = []; //Clear the array before restructuring.
                querySnapshot.forEach((doc) => {
                    this.organizationsObj[doc.id] = doc.data();
                    let datapush = {id: doc.id, name: doc.data().name, enabled: doc.data().enabled, contact: doc.data().contact, location: doc.data().location, domain: doc.data().domain};
                    this.organizations.push(datapush);         
                console.log(this.orgobj);
                });
                console.log(this.organizations);
              

            },            

            async SaveOrg() {
                if(!this.$refs.saveOrgForm.validate()){return} //Exit the function if form is not validated
                try {
                this.saving = true; //Turn on the loading button
                await setDoc(doc(db, "organizations", this.selectedOrg), this.organizationsObj[this.selectedOrg], { merge: true });
                this.saving = false; //Turn off the loading button
                this.settings = false; //Close the modal
                this.$store.commit('alertpop', { message: 'Saved successfully.', type: "success" });
                this.getOrgs(); //Redo the list of orgs
                  } catch(e) {
                    this.saving = false; //Turn off the loading button
                    this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });
                    console.error(e);
                    } 
            },
            async SaveNewProj() {
                if(!this.$refs.newProjForm.validate()){return} //Exit the function if form is not validated

                  // Get a reference to the 'copyMasterProject' Firebase function
                  const functions = getFunctions();
                  const copyMasterProject = httpsCallable(functions, 'copyMasterProject');
                  this.saving = true; //Turn on the loading button
                  this.$store.commit('alertpop', { message: 'Creating the project. This may take a few minutes...', type: "success" });                          

                  try {
                      // Call the function with the data from the 'newproject' object
                      const result = await copyMasterProject(this.newproject);

                      // Handle the result
                      if (result.data.status === 'complete') {
                          this.$store.dispatch('loadProjects')
                          console.log('Project created with ID:', result.data.newProjectId);
                          this.saving = false;
                          this.newproj = false;                          
                          this.$store.commit('alertpop', { message: 'The project has been created successfully.', type: "success" });       

                      } else {
                          console.error('Error creating project:', result.data.error);
                          this.saving = false;
                          this.newproj = false;
                          this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });                          
                      }
                  } catch (error) {
                      console.error('Error calling Firebase function:', error);
                  }                

            },
            loadProjects() {
              this.rowData = [...this.$store.state.projMasterRay];
            },
        },

        beforeMount() {
            if (this.$store.getters.loaded == false){ this.$store.commit('beginLoading') }
            document.title = "Vantage - Projects";
            this.context = {componentParent: this};
            this.columnDefs = [
                { headerName: 'Organization', field: 'orgname', minWidth: 100, sortable: true, filter: true, resizable: true },
                { headerName: 'Name', field: 'name', minWidth: 100, sortable: true, filter: true, resizable: true, flex: 1, cellRenderer: ListDocuProjLink, sort: 'asc' },
                { headerName: 'Action', field: 'id', minWidth: 50, sortable: true, filter: true, resizable: true, cellRenderer: ListButtonDocuProj}
            ];
            //console.log(this.$store.state.count);
        },
        mounted() {
          //this.rowData = this.$store.state.projMasterRay; //load projects into list from store
          this.loadProjects()
          console.log("projects: ",this.$store.state.projMasterRay)
          this.getOrgs();
        },
        watch: { 
          "$store.state.projMasterRay": {
            handler(nv) {
              console.log("proj master ray changed");
              console.log("updated proj data", this.$store.state.projMasterRay)
              //this.rowData = this.$store.state.projMasterRay; //load projects into list from store
              this.loadProjects()
            },
            deep: true
          }
        }
    }
</script>

<style scoped>

#ev-list-bar {
    display: flex;
    flex-wrap: nowrap;
    background-color: #3d4856;
    white-space: nowrap;
    overflow: hidden;

}

.whole-table {
    justify-content: center;
    width: 100%;
    max-width: 1000px;
    height: 90%;
    
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.main-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
} 

</style>