<template>
<div>

<a class="a-link text-button" @click="openBtnClicked()">{{ this.params.value}}</a>

</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    methods: {
        openBtnClicked() {
            console.log(this.params.value)
            this.params.context.componentParent.openProject(this.params.data.id)

            //alert(this.params.context.componentParent.selectedUser);

        }    
    }
});
</script>

<style>
.a-link {
  text-decoration: none; /* removes the underline */
  color: #007bff; /* changes the link color */
}

.a-link:hover {
   text-decoration: underline !important;
   cursor: pointer;
}
</style>