<template>
<div>

    <v-dialog v-model="dialog" persistent :width="options.width" v-bind:style="{ zIndex: options.zIndex }">
        <v-card dark>
            <v-card-title>{{ options.title }}</v-card-title>
            <v-card-text>{{ options.message }}</v-card-text>
            <v-card-actions>
            <v-btn @click="agree">{{ options.yes }}</v-btn>
            <v-btn @click="cancel">{{ options.no }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: "confirmation",
    data() {
    return {
        dialog: false,
        options: { width: 400, zIndex: 9999, message: "Default", title: "Default", yes: "Yes", no: "No" },
        resolve: null,
        reject: null
      }
    },
    methods: {
        open(options) {
            this.dialog = true;
            this.options = Object.assign(this.options, options);
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        agree() {
            this.resolve(true);
            this.dialog = false;
        },
        cancel() {
            this.resolve(false);
            this.dialog = false;
        }
    }
});
</script>