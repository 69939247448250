<template>
    <div>

        <draggable v-model="myArray" item-key="id">
            <template #item="{element}">
                <div>
                <div v-if="element.id == 1" class="draggable-item"> One </div>
                <div v-if="element.id == 2" class="draggable-item"> Two </div>
                <div v-if="element.id == 3" class="draggable-item"> Three </div>
                </div>
            </template>
        </draggable>

    </div>
</template>

<script>
import { ref } from 'vue';
import draggable from 'vuedraggable';

export default {
    components: {
        draggable,
    },
    data() {
        return {
            myArray: [
                { id: 1, name: 'Div 1' },
                { id: 2, name: 'Div 2' },
                { id: 3, name: 'Div 3' },
            ],

        };
    },
};
</script>

<style scoped>
.draggable-item {
    background-color: #f0f0f0;
    padding: 10px;
    margin-bottom: 10px;
}
</style>