<template>

<v-container v-if="this.$store.getters.loaded == false">
    <v-overlay opacity="1" value="true">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
</v-container>

<v-container v-else class="py-5 pl-5" style="width: 100%; height: 100%;">



  <v-row justify="center">
    <v-col md="11" class="py-0">
      <v-card elevation="0" color="#e7e7e7" >
        <v-card-title><span class="text-uppercase text-h6">{{ this.$store.state.projMasterObj[this.currentProject].name }} Dashboard</span></v-card-title>
         <v-card-subtitle class="text-subtitle-2" style="color: #64748b">Monitor metrics, check reports and review performance</v-card-subtitle>
      </v-card>
    </v-col>

    <v-col md="11">

    <v-card elevation="2" class="rounded-xl">
      <v-card-title></v-card-title>
      <v-card-subtitle class="pt-6 pl-8">Implementation Score by Domain</v-card-subtitle>

      <v-card-text>
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
      </v-card-text>

    </v-card>

    </v-col>
  </v-row>

          


</v-container>
</template>

<script>


    export default {
        name: 'Home',
        data() {
            return {
                columnDefs: null,
                autoGroupColumnDef: null,
                currentProject: null,          
                series: [],
                chartOptions: {
                  chart: {
                    type: 'bar',
                    height: 350,
                    width: '100%',
                    stacked: true,
                    toolbar: {
                      show: true
                    },
                    zoom: {
                      enabled: true
                    },
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 400,
                    }
                  },

                  plotOptions: {
                    bar: {
                      horizontal: false,
                      borderRadius: 10
                    },
                  },
                  xaxis: {
                    categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19' ],
                  },
                  legend: {
                    position: 'right',
                    offsetY: 40
                  },
                  fill: {
                    opacity: 1
                  }
                },
                

            }
        },
        components: {

        },
        methods: {
          test(){

            },

            roundScore(score){
              if (score >= 0 && score <= 10) { return 0 } //Very low
              if (score >= 11 && score <= 32) { return 25 } //Low
              if (score >= 33 && score <= 65) { return 50 } //Moderate
              if (score >= 66 && score <= 89) { return 75 } //High
              if (score >= 90 && score <= 100) { return 100 } //Very high
            },
            loadCharts(){
              console.log("charts loading")

              let series = [{name: 'Implemented', data: []}] //[0] is policies, [1] is process
              let DomainCnt = {
                1: {count: 0, polscore: 0, procscore: 0, implscore: 0},
                2: {count: 0, polscore: 0, procscore: 0, implscore: 0},
                3: {count: 0, polscore: 0, procscore: 0, implscore: 0},
                4: {count: 0, polscore: 0, procscore: 0, implscore: 0},
                5: {count: 0, polscore: 0, procscore: 0, implscore: 0},
                6: {count: 0, polscore: 0, procscore: 0, implscore: 0},
                7: {count: 0, polscore: 0, procscore: 0, implscore: 0},
                8: {count: 0, polscore: 0, procscore: 0, implscore: 0},
                9: {count: 0, polscore: 0, procscore: 0, implscore: 0},
                10: {count: 0, polscore: 0, procscore: 0, implscore: 0},
                11: {count: 0, polscore: 0, procscore: 0, implscore: 0},
                12: {count: 0, polscore: 0, procscore: 0, implscore: 0},
                13: {count: 0, polscore: 0, procscore: 0, implscore: 0},
                14: {count: 0, polscore: 0, procscore: 0, implscore: 0},
                15: {count: 0, polscore: 0, procscore: 0, implscore: 0},
                16: {count: 0, polscore: 0, procscore: 0, implscore: 0},
                17: {count: 0, polscore: 0, procscore: 0, implscore: 0},
                18: {count: 0, polscore: 0, procscore: 0, implscore: 0},
                19: {count: 0, polscore: 0, procscore: 0, implscore: 0},
              }

              // if (currentObj.dom == "01 Information Protection Program"){ DomainCnt["1"].count++ ;  DomainCnt["1"].polscore += parseInt(currentObj.polscore, 10); DomainCnt["1"].procscore += parseInt(currentObj.procscore, 10) }
              // if (currentObj.dom == "02 Endpoint Protection"){ DomainCnt["2"].count++ ;  DomainCnt["2"].polscore += parseInt(currentObj.polscore, 10); DomainCnt["2"].procscore += parseInt(currentObj.procscore, 10) }
              // if (currentObj.dom == "03 Portable Media Security"){ DomainCnt["3"].count++ ;  DomainCnt["3"].polscore += parseInt(currentObj.polscore, 10); DomainCnt["3"].procscore += parseInt(currentObj.procscore, 10) }
              // if (currentObj.dom == "04 Mobile Device Security"){ DomainCnt["4"].count++ ;  DomainCnt["4"].polscore += parseInt(currentObj.polscore, 10); DomainCnt["4"].procscore += parseInt(currentObj.procscore, 10) }
              // if (currentObj.dom == "05 Wireless Security"){ DomainCnt["5"].count++ ;  DomainCnt["5"].polscore += parseInt(currentObj.polscore, 10); DomainCnt["5"].procscore += parseInt(currentObj.procscore, 10) }
              // if (currentObj.dom == "06 Configuration Management"){ DomainCnt["6"].count++ ;  DomainCnt["6"].polscore += parseInt(currentObj.polscore, 10); DomainCnt["6"].procscore += parseInt(currentObj.procscore, 10) }
              // if (currentObj.dom == "07 Vulnerability Management"){ DomainCnt["7"].count++ ;  DomainCnt["7"].polscore += parseInt(currentObj.polscore, 10); DomainCnt["7"].procscore += parseInt(currentObj.procscore, 10) }
              // if (currentObj.dom == "08 Network Protection"){ DomainCnt["8"].count++ ;  DomainCnt["8"].polscore += parseInt(currentObj.polscore, 10); DomainCnt["8"].procscore += parseInt(currentObj.procscore, 10) }
              // if (currentObj.dom == "09 Transmission Protection"){ DomainCnt["9"].count++ ;  DomainCnt["9"].polscore += parseInt(currentObj.polscore, 10); DomainCnt["9"].procscore += parseInt(currentObj.procscore, 10) }
              // if (currentObj.dom == "10 Password Management"){ DomainCnt["10"].count++ ;  DomainCnt["10"].polscore += parseInt(currentObj.polscore, 10); DomainCnt["10"].procscore += parseInt(currentObj.procscore, 10) }
              // if (currentObj.dom == "11 Access Control"){ DomainCnt["11"].count++ ;  DomainCnt["11"].polscore += parseInt(currentObj.polscore, 10); DomainCnt["11"].procscore += parseInt(currentObj.procscore, 10) }
              // if (currentObj.dom == "12 Audit Logging & Monitoring"){ DomainCnt["12"].count++ ;  DomainCnt["12"].polscore += parseInt(currentObj.polscore, 10); DomainCnt["12"].procscore += parseInt(currentObj.procscore, 10) }
              // if (currentObj.dom == "13 Education, Training and Awareness"){ DomainCnt["13"].count++ ;  DomainCnt["13"].polscore += parseInt(currentObj.polscore, 10); DomainCnt["13"].procscore += parseInt(currentObj.procscore, 10) }
              // if (currentObj.dom == "14 Third Party Assurance"){ DomainCnt["14"].count++ ;  DomainCnt["14"].polscore += parseInt(currentObj.polscore, 10); DomainCnt["14"].procscore += parseInt(currentObj.procscore, 10) }
              // if (currentObj.dom == "15 Incident Management"){ DomainCnt["15"].count++ ;  DomainCnt["15"].polscore += parseInt(currentObj.polscore, 10); DomainCnt["15"].procscore += parseInt(currentObj.procscore, 10) }
              // if (currentObj.dom == "16 Business Continuity & Disaster Recovery"){ DomainCnt["16"].count++ ;  DomainCnt["16"].polscore += parseInt(currentObj.polscore, 10); DomainCnt["16"].procscore += parseInt(currentObj.procscore, 10) }
              // if (currentObj.dom == "17 Risk Management"){ DomainCnt["17"].count++ ;  DomainCnt["17"].polscore += parseInt(currentObj.polscore, 10); DomainCnt["17"].procscore += parseInt(currentObj.procscore, 10) }
              // if (currentObj.dom == "18 Physical & Environmental Security"){ DomainCnt["18"].count++ ;  DomainCnt["18"].polscore += parseInt(currentObj.polscore, 10); DomainCnt["18"].procscore += parseInt(currentObj.procscore, 10) }
              // if (currentObj.dom == "19 Data Protection & Privacy"){ DomainCnt["19"].count++ ;  DomainCnt["19"].polscore += parseInt(currentObj.polscore, 10); DomainCnt["19"].procscore += parseInt(currentObj.procscore, 10) }              

            Object.keys(this.$store.state.docuMaster[this.currentProject]).forEach((key) => {
              let currentObj = this.$store.state.docuMaster[this.currentProject][key]
              if (currentObj.dom == "01 Information Protection Program") {
                if (currentObj.implscore !== "NA") {
                  DomainCnt["1"].count++;
                  DomainCnt["1"].implscore += parseInt(currentObj.implscore, 10);
                }
              }
              if (currentObj.dom == "02 Endpoint Protection") {
                if (currentObj.implscore !== "NA") {
                  DomainCnt["2"].count++;
                  DomainCnt["2"].implscore += parseInt(currentObj.implscore, 10);
                }
              }
              if (currentObj.dom == "03 Portable Media Security") {
                if (currentObj.implscore !== "NA") {
                  DomainCnt["3"].count++;
                  DomainCnt["3"].implscore += parseInt(currentObj.implscore, 10);
                }
              }
              if (currentObj.dom == "04 Mobile Device Security") {
                if (currentObj.implscore !== "NA") {
                  DomainCnt["4"].count++;
                  DomainCnt["4"].implscore += parseInt(currentObj.implscore, 10);
                }
              }
              if (currentObj.dom == "05 Wireless Security") {
                if (currentObj.implscore !== "NA") {
                  DomainCnt["5"].count++;
                  DomainCnt["5"].implscore += parseInt(currentObj.implscore, 10);
                }
              }
              if (currentObj.dom == "06 Configuration Management") {
                if (currentObj.implscore !== "NA") {
                  DomainCnt["6"].count++;
                  DomainCnt["6"].implscore += parseInt(currentObj.implscore, 10);
                }
              }
              if (currentObj.dom == "07 Vulnerability Management") {
                if (currentObj.implscore !== "NA") {
                  DomainCnt["7"].count++;
                  DomainCnt["7"].implscore += parseInt(currentObj.implscore, 10);
                }
              }
              if (currentObj.dom == "08 Network Protection") {
                if (currentObj.implscore !== "NA") {
                  DomainCnt["8"].count++;
                  DomainCnt["8"].implscore += parseInt(currentObj.implscore, 10);
                }
              }
              if (currentObj.dom == "09 Transmission Protection") {
                if (currentObj.implscore !== "NA") {
                  DomainCnt["9"].count++;
                  DomainCnt["9"].implscore += parseInt(currentObj.implscore, 10);
                }
              }
              if (currentObj.dom == "10 Password Management") {
                if (currentObj.implscore !== "NA") {
                  DomainCnt["10"].count++;
                  DomainCnt["10"].implscore += parseInt(currentObj.implscore, 10);
                }
              }
              if (currentObj.dom == "11 Access Control") {
                if (currentObj.implscore !== "NA") {
                  DomainCnt["11"].count++;
                  DomainCnt["11"].implscore += parseInt(currentObj.implscore, 10);
                }
              }
              if (currentObj.dom == "12 Audit Logging & Monitoring") {
                if (currentObj.implscore !== "NA") {
                  DomainCnt["12"].count++;
                  DomainCnt["12"].implscore += parseInt(currentObj.implscore, 10);
                }
              }
              if (currentObj.dom == "13 Education, Training and Awareness") {
                if (currentObj.implscore !== "NA") {
                  DomainCnt["13"].count++;
                  DomainCnt["13"].implscore += parseInt(currentObj.implscore, 10);
                }
              }
              if (currentObj.dom == "14 Third Party Assurance") {
                if (currentObj.implscore !== "NA") {
                  DomainCnt["14"].count++;
                  DomainCnt["14"].implscore += parseInt(currentObj.implscore, 10);
                }
              }
              if (currentObj.dom == "15 Incident Management") {
                if (currentObj.implscore !== "NA") {
                  DomainCnt["15"].count++;
                  DomainCnt["15"].implscore += parseInt(currentObj.implscore, 10);
                }
              }
              if (currentObj.dom == "16 Business Continuity & Disaster Recovery") {
                if (currentObj.implscore !== "NA") {
                  DomainCnt["16"].count++;
                  DomainCnt["16"].implscore += parseInt(currentObj.implscore, 10);
                }
              }
              if (currentObj.dom == "17 Risk Management") {
                if (currentObj.implscore !== "NA") {
                  DomainCnt["17"].count++;
                  DomainCnt["17"].implscore += parseInt(currentObj.implscore, 10);
                }
              }
              if (currentObj.dom == "18 Physical & Environmental Security") {
                if (currentObj.implscore !== "NA") {
                  DomainCnt["18"].count++;
                  DomainCnt["18"].implscore += parseInt(currentObj.implscore, 10);
                }
              }
              if (currentObj.dom == "19 Data Protection & Privacy") {
                if (currentObj.implscore !== "NA") {
                  DomainCnt["19"].count++;
                  DomainCnt["19"].implscore += parseInt(currentObj.implscore, 10);
                }
              }
              //if (currentObj.dom == ""){ DomainCnt[""].count++ ;  DomainCnt[""].polscore += parseInt(currentObj.polscore, 10); DomainCnt[""].procscore += parseInt(currentObj.procscore, 10) }
              });

            Object.keys(DomainCnt).forEach((key) => {
              series[0].data.push(Math.trunc(DomainCnt[key].implscore / DomainCnt[key].count))
            });

            console.log(DomainCnt)
            console.log(series)

            setTimeout(() => {this.series = series}, 500); //wait 1 second for transitions to finish
            //this.series = series
            }


        },
        beforeCreate(){

        },
        created(){
          
        },
        beforeMount() {
          if (this.$store.getters.loaded == false){ this.$store.commit('beginLoading') }
          //get the current project id from the URL
          this.currentProject = this.$route.query.project

          //if project is already in store then load data to rows, if not then load database and wait for watcher
          if (this.currentProject in this.$store.state.loadedProjects && this.$store.state.loadedProjects[this.currentProject] == true ) {

            this.loadCharts()
            if (this.$store.state.initializing.projects == true){ document.title = "Vantage - Dashboard - " + this.$store.state.projMasterObj[this.currentProject].name }
            
            } else {
            this.$store.dispatch('loadProject', { projid: this.currentProject })
            }     


        },

        mounted() {        

          
          console.log("mounted home")
          
        },
        computed: {

        },
        watch: { //watch for database changes and update the list

          "$store.state.initializing.projects"(nv) {
            console.log("project change", nv)
            if (nv == true){
              document.title = "Vantage - " + this.$store.state.projMasterObj[this.currentProject].name
             }

          },
          "$store.state.loadedProjects": {
            handler: function (val, oldVal) {
              //if new project is loaded check if its set to true and then load the row data
              if (this.currentProject in val && val[this.currentProject] == true){ 

               this.loadCharts()
               if (this.$store.state.initializing.projects == true){ document.title = "Vantage - Dashboard - " + this.$store.state.projMasterObj[this.currentProject].name }

                }
      
            },
            deep: true
          }
        }

    }
</script>

<style>

.no-active {
  color: rgba(255, 255, 255, 0.7) !important
}


.ag-theme-alpine div.ag-row {
    font-size: 13px !important;
}

.ag-header-cell-label {
    font-family: "Roboto", sans-serif !important;
    text-transform: uppercase !important;
    font-size: 0.75rem !important;
    font-weight: 500;
    letter-spacing: 0.0892857143em !important;
}

</style>
