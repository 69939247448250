<template>
<v-container v-if="this.$store.getters.loaded == false">
    <v-overlay opacity="1" value="true">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
</v-container>

<v-container v-else class="about">
<FileMonitor />
<v-btn small dark @click="test1.asdf = 'blah'">Test</v-btn>
    <v-bottom-sheet v-model="sheet" width="500" hide-overlay persistent no-click-animation>
      <v-sheet class="text-center" height="200px">

        <v-card color="#00000087" dark>
            <v-card-title class="text-button">File Queue &nbsp; <v-chip color="white" text-color="black" x-small>{{ this.$store.state.FileQueue.length }} </v-chip></v-card-title>
            <v-card-subtitle>
                <v-chip small color="green">{{ this.$store.state.uploadFile.status }}</v-chip>
            </v-card-subtitle>

            <v-card-text>
            {{ this.$store.state.uploadFile.name }}
            <v-progress-linear height="5" striped :value="this.$store.state.uploadFile.progress" color="green"></v-progress-linear>

            </v-card-text>

            <v-card-actions>

            </v-card-actions>
        </v-card>

      </v-sheet>
    </v-bottom-sheet>



  </v-container>
</template>

<script>

import FileMonitor from '@/components/FileMonitor.vue'
    export default {
        name: 'Home',
        data() {
            return {
                columnDefs: null,
                temp: 1,
                sheet: true,
                test1: { abc: "HEY" },
            }
        },
        components: {
            FileMonitor
        },

        methods: {
        increment() {
            //this.$store.commit('increment')
            this.$store.state.docuMaster["0101.00a1Organizational.123"].dom = "changed";
            console.log(this.$store.state.docuMaster2)

        }
        },
        created() {
            console.log("created about")
            //console.log(this.$store.state.count);
        },
        mounted() {
            document.title = "Vantage About";
            console.log("mounted about")
            //console.log(this.$store.state.count);
        },
        computed: {
          test(){
            return this.test1
          }
        },

        watch: {
          test: {
              handler: function(val, oldVal) {
              console.log("newval", val);
            },
            deep: true
          }
        }
    }
</script>