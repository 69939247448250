<template>
<div>

        <input type="checkbox" @click="checkedHandler($event)" :checked="params.value" style="width: 15px; height: 15px"/>

</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    methods: {
        checkedHandler(event) {
        let checked = event.target.checked;        
        let colId = this.params.column.colId;        
        //let rowID = this.params.node.getRowId()
        this.params.node.setDataValue(colId, checked);
        //this.params.context.componentParent.gridApi.applyTransaction({ update: this.params.node.data });
        //let rowNode = this.params.context.componentParent.gridApi.getRowNode(this.params.node.id);
        //rowNode.setDataValue(colId, checked);

        //set to not a random sample selection        
        this.params.context.componentParent.InventoryData[this.params.context.componentParent.currentInventory.id].rand = false
        //recompute selected count
        this.params.context.componentParent.reComp = !this.params.context.componentParent.reComp
        //count selected
        this.params.context.componentParent.countSelected()

        },
    }
});
</script>